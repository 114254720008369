import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModal } from '../../redux/actions/components';

import {
  addJobsToTracker,
  toggleSelectJobMatch
} from '../../redux/actions/job-matches';

import {
  selectSelectedJobMatches,
  selectIsSubmittingJobMatch
} from '../../redux/selectors/job-matches';

import { selectModal } from '../../redux/selectors/components';

import moment from 'moment';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { ProgressBar } from 'react-step-progress-bar';
import {
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';

import CpHtmlParser from '../common/CpHtmlParser';

import MatchScoreModal from './MatchScoreModal';

const employmentTypeOptions = [
  'Full-Time',
  'Part-Time',
  'Full-Time/Part-Time'
];

const educationLevelOptions = [
  'Secondary Education',
  'Vocational Education',
  'Bachelor',
  'Master',
  'Post-Master'
];

const dateConfig = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[a week ago]',
  sameElse: 'MM/DD/YYYY'
};

const JobMatchCard = ({ job, modal, actions, isSubmitting, selectedJobMatches }) => {
  const percentageScore = Math.round(job.score/1*100);
  let progressBarFillColor = '';

  switch(true) {
  case percentageScore <= 39:
    progressBarFillColor = 'rgba(240, 83, 35, 1)';
    break;

  case percentageScore <= 59:
    progressBarFillColor = 'rgba(234, 193, 88, 1)';
    break;

  default:
    progressBarFillColor = 'rgba(42, 189, 56, 1)';
    break;
  }

  const [tooltipOpen, setTooltipOpen] = useState(false);

  function openModal() {
    actions.openModal(`match-score-${job.id}`);
  }

  function toggleTooltip() {
    setTooltipOpen(!tooltipOpen);
  }

  function toggleSelectJobMatch(event) {
    actions.toggleSelectJobMatch(event.target.value);
  }

  async function onSaveJob(event) {
    event.preventDefault();

    await actions.addJobsToTracker({ jobMatchesIds: [job.id] });
    toastr.success('', 'This job was successfully saved! See all your saved jobs in "Job Tracker".');
  }

  const seeMoreCondition = job.description.replace(/<[^>]+>/g, '').length > 205;
  const descriptionString = job.description.replace(/<[^>]+>/g, '');
  const description = seeMoreCondition ?
    `${descriptionString.slice(0, 205)}...` : descriptionString.length > 0 ?
      descriptionString :
      'To see the full description of this job, you must visit the site it is published on';

  const titleString = job.title.replace(/<[^>]+>/g, '');
  const isTitleMoreThanLimit = titleString.length > 30;
  const title = isTitleMoreThanLimit ?
    `${titleString.slice(0, 30)}...` : titleString;

  const city = job.city.replace(/<[^>]+>/g, '');

  const educationLevelOption = educationLevelOptions[parseInt(job.educationLevel)-1];
  const educationLevel = educationLevelOption ? educationLevelOption : 'Unspecified';

  const employmentTypeOption = employmentTypeOptions[parseInt(job.employmentType)-1];
  const employmentType = employmentTypeOption ? employmentTypeOption : 'Unspecified';

  const displayDate = moment(job.postingDate.date).calendar(dateConfig);
  const displayDateClassNames = classNames({
    'badge badge-pill': displayDate === 'Today',
    'card-text': displayDate !== 'Today'
  });

  const tooltipText = isSubmitting ? 'Saving...' : job.status ? 'Saved Job' : 'Save Job';

  const isMatchScoreModalOpen = modal === `match-score-${job.id}`;

  return (
    <div className="jobmatch-card-container">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title-container">
            <div className="card-title">
              <input
                type="checkbox"
                value={job.id}
                className="mr-10"
                checked={selectedJobMatches.includes(job.id)}
                onChange={toggleSelectJobMatch}/>

              <a href={job.url} target="_blank">{title}</a>
            </div>
            <div className="card-title-meta">
              <span className={displayDateClassNames}>{displayDate}</span>
              <span
                id={`Tooltip-${job.id}`}
                className="clickable-link"
                onClick={job.status ? undefined: onSaveJob}>
                <i className={classNames('fa-bookmark', {
                  'job-saved': job.status,
                  'fas': job.status,
                  'far': !job.status
                })}/>
              </span>
            </div>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={`Tooltip-${job.id}`}
              toggle={toggleTooltip}>
              {tooltipText}
            </Tooltip>
          </h5>
          <div className="jobmatch-card-location-type">
            <p className="card-text mb-0">
              {job.company} - {city}
            </p>
            <div className="jobmatch-type">
              <p className="card-text mb-0">
                <b>Employment Type: </b>{employmentType}
              </p>
              <p className="card-text mb-0">
                <b>Education Level: </b>{educationLevel}
              </p>
            </div>
          </div>
          <div className="jobmatch-card-description">
            <CpHtmlParser htmlString={description} />
            <a
              target="_blank"
              href={job.url}
              className="show-more-btn-job">
              See more
            </a>
          </div>

          <div className="jobmatch-card-footer">
            <div
              onClick={openModal}
              className="jobmatch-card-progress clickable">
              <ProgressBar
                percent={percentageScore}
                filledBackground={progressBarFillColor} />
            </div>
            <div className="jobmatch-card-score">
              <span
                onClick={openModal}
                className="jobmatch-card-score-text">
                Match Score: {percentageScore}
              </span>
              <i id={`tooltip-${job.id}`} className="fa-sm ml-1 fas fa-question-circle clickable"/>
              <UncontrolledTooltip placement="top" target={`tooltip-${job.id}`} trigger="click">
                Match score indicates how well you match to the job and specifies where you match and what you’re missing
              </UncontrolledTooltip>
              {isMatchScoreModalOpen &&
                <MatchScoreModal
                  job={job}
                  progressBarFillColor={progressBarFillColor}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

JobMatchCard.propTypes = {
  modal: PropTypes.string,
  job: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  selectedJobMatches: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  modal: selectModal(state),
  isSubmitting: selectIsSubmittingJobMatch(state),
  selectedJobMatches: selectSelectedJobMatches(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    addJobsToTracker,
    toggleSelectJobMatch
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobMatchCard);
