import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty, isUndefined } from 'lodash';

import { withHooks } from '../../utils/withHooks';

import { Modal } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import Validator from '../../validator';
import RegistrationForm from '../../components/auth/RegistrationForm';

import * as routerActions from '../../redux/actions/router';
import * as authActions from '../../redux/actions/authentication';
import * as registrationActions from '../../redux/actions/registration';

let userData = { email: '', confirmEmail: '', groupCode: '' };

class Registration extends Component {
  static propTypes = {
    userData: PropTypes.object,
    modal: PropTypes.string,
    auth: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    toggleSignUpModal: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      displayAlertType: '',
      userData: Object.assign({}, this.props.userData)
    };

    this.dismissAlert = this.dismissAlert.bind(this);
    this.refNameField = this.refNameField.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.onClickTerms = this.onClickTerms.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.updateUserDataState = this.updateUserDataState.bind(this);
  }

  componentDidMount(){
    if (this.name) {
      this.name.focus();
    }
  }

  isValid(field = null) {
    const { userData, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      email: ['required', 'email'],
      confirmEmail: ['required', 'email', 'match|email'],
      groupCode: ['minLength|10']
    }, userData, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  renderErrors() {
    let errorMessage = [];
    let { error } = this.props.registration;

    if (Object.keys(error).length > 0) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        errorMessage.push(error.message);
      } else {
        Object.keys(error).map(key => errorMessage.push(error[key][0]));
      }

      if (errorMessage.length == 0) return false;
      toastr.error(errorMessage.join('<br/>'));
    }
  }

  updateUserDataState(event) {
    const { name, value } = event.target;
    let { userData } = this.state;

    userData[name] = value.trim();

    this.setState({ userData }, () => this.isValid(name));
  }

  registerUser(event) {
    event.preventDefault();

    if (! this.isValid()) return false;

    this.props
      .actions
      .requestSelfRegistration(this.state.userData)
      .then(() => {
        const { registration } = this.props;
        let displayAlertType = 'success';

        if ((! isEmpty(registration.error)) && (! isUndefined(registration.error.errorType))) {
          userData = this.state.userData;
          displayAlertType = (registration.error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : 'danger';
        }

        this.setState({ userData, displayAlertType });
      })
      .catch((error) => {
        let displayAlertType = 'danger';

        if ((! isEmpty(error)) && (! isUndefined(error.errorType))) {
          displayAlertType = (error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : displayAlertType;
        }

        this.setState({ displayAlertType });
      });
  }

  autoLogin() {
    const { email, password } = this.state.userData;

    if (this.props.registration.status) {
      this.props.actions.authenticateUser({ email, password })
        .then(() => this.redirectAndCloseModal());
    }
  }

  dismissAlert() {
    this.setState({ displayAlertType: '' });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.registerUser(event);

    if (event.key === 'Escape')
      return this.toggleLoginModal();
  }

  onClickTerms(event){
    event.preventDefault();

    this.props.toggleSignUpModal();
    this.props.actions.redirect('/terms');
    this.props.navigate('/terms');
  }

  refNameField(name) {
    this.name = name;
  }

  render() {
    const { registration, toggleSignUpModal, auth, modal } = this.props;
    const { userData, errors, displayAlertType } = this.state;
    const isOpenModal = modal && modal === 'signup-modal';

    return (
      <Modal
        isOpen={isOpenModal}
        size={'lg'}
        toggle={toggleSignUpModal}
        className="signupModal">
        <div className="modal-body homepage-modal-body">
          <button onClick={toggleSignUpModal} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title" id="myModalLabel"/>
          <RegistrationForm
            errors={errors}
            userData={userData}
            nameRef={this.refNameField}
            toggleSignUpModal={toggleSignUpModal}
            isRequestingLogin={auth.isRequesting}
            handleKeyPress={this.handleKeyPress}
            onChange={this.updateUserDataState}
            onClickTerms={this.onClickTerms}
            onSubmit={this.registerUser}
            displayAlertType={displayAlertType}
            registration={registration}
            dismissAlert={this.dismissAlert}/>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, router, registration, components: { modal } } = state;

  return {
    auth,
    modal,
    router,
    userData,
    registration
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {}, routerActions, authActions, registrationActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Registration));
