import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import SkillBadgeModal from '../../components/home/SkillBadgeModal';
import IndustryMentorsModal from '../../components/home/IndustryMentorsModal';
import SkillBuildersModalContainer from '../home/SkillBuildersModalContainer';

import { openModal } from '../../redux/actions/components';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

const careerPortfolio = require('../../assets/images/homepage/slide1-1.png');
const skillBuilders = require('../../assets/images/homepage/slide1-2.png');
const skillBadges = require('../../assets/images/homepage/slide1-3.png');
const skillFeedback = require('../../assets/images/homepage/slide1-4.png');
const skillEndorsements = require('../../assets/images/homepage/slide1-5.png');
const careerSite = require('../../assets/images/homepage/slide2-1.png');
const jobMatch = require('../../assets/images/homepage/slide2-2.png');
const jobTracker = require('../../assets/images/homepage/slide2-3.png');
const resumeBuilder = require('../../assets/images/homepage/slide2-4.png');
const interviewVideos = require('../../assets/images/homepage/slide2-5.png');

class Toolkit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skillBuilders: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeSlideOne: 'careerPortfolio',
      activeSlideTwo: 'careerSite',
      showSkillBadgeModal: false,
      showSkillBuildersModal: false,
      showIndustryMentorsModal: false
    };

    this.onClickSlide = this.onClickSlide.bind(this);
    this.toggleSkillBadgeModal = this.toggleSkillBadgeModal.bind(this);
    this.toggleSkillBuildersModal = this.toggleSkillBuildersModal.bind(this);
    this.toggleJoinMembershipModal = this.toggleJoinMembershipModal.bind(this);
    this.toggleIndustryMentorsModal = this.toggleIndustryMentorsModal.bind(this);
  }

  componentDidMount(){
    const { skillBuildersRequest } = this.props.actions;

    skillBuildersRequest();
  }

  toggleSkillBadgeModal(event){
    event.preventDefault();

    this.setState({
      showSkillBadgeModal: !this.state.showSkillBadgeModal
    });
  }

  toggleSkillBuildersModal(event){
    event.preventDefault();

    this.setState({ showSkillBuildersModal: !this.state.showSkillBuildersModal });
  }

  toggleIndustryMentorsModal(event) {
    event.preventDefault();

    this.setState({ showIndustryMentorsModal: !this.state.showIndustryMentorsModal });
  }

  onClickSlide(event) {
    event.preventDefault();

    const { id, dataset: { name } } = event.target;

    this.setState({ [name]: id });
  }

  toggleJoinMembershipModal() {
    this.props.actions.openModal('join-membership-modal');
  }

  render() {
    const {
      activeSlideOne,
      activeSlideTwo,
      showSkillBadgeModal,
      showSkillBuildersModal,
      showIndustryMentorsModal
    } = this.state;

    const { isRequesting, data: skillBuildersData } = this.props.skillBuilders;

    return (
      <div className="for-org non-logged-homepage">
        <Helmet title="Toolkit"/>

        <div className="talent-development">
          <div className="toolkit">
            <header>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h1>
                      We empower you with effective tools all in one place to help you achieve lifelong career success.
                    </h1>
                  </div>
                </div>
              </div>
            </header>
            <section className="section-skills">
              <h1>Tools To Help You Demonstrate Your Skills</h1>
              <div className="ss-navigation">
                <a
                  href="#"
                  id="careerPortfolio"
                  data-name="activeSlideOne"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideOne === 'careerPortfolio' })}>
                  Career Portfolio
                </a>
                <a
                  href="#"
                  id="skillBuilders"
                  data-name="activeSlideOne"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideOne === 'skillBuilders' })}>
                  Skill Builders
                </a>
                <a
                  href="#"
                  id="skillBadges"
                  data-name="activeSlideOne"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideOne === 'skillBadges' })}>
                  Skill Badges
                </a>
                <a
                  href="#"
                  id="skillFeedback"
                  data-name="activeSlideOne"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideOne === 'skillFeedback' })}>
                  Skill Feedback
                </a>
                <a
                  href="#"
                  id="skillEndorsements"
                  data-name="activeSlideOne"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideOne === 'skillEndorsements' })}>
                  Skill Endorsements
                </a>
              </div>

              <div className="gallery">
                <div className={
                  classNames('gallery-item',
                    {
                      'animated fadeIn': activeSlideOne === 'careerPortfolio',
                      'active': activeSlideOne === 'careerPortfolio'
                    })}>
                  <div className="container">
                    <div className="row row-eq-height">

                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Career Portfolio</div>
                          </div>
                          <div className="description">
                            <p>
                              Build a treasure chest of evidence that shows your skills and achievements. Use privacy settings to control visibility and get unlimited storage to save multimedia files such as projects, work samples, and awards that prove your abilities more than simple keywords on a resume.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={careerPortfolio} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={
                  classNames('gallery-item',
                    {
                      'animated fadeIn': activeSlideOne === 'skillBuilders',
                      'active': activeSlideOne === 'skillBuilders'
                    })}>

                  <div className="container">
                    <div className="row row-eq-height">

                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Skill Builders</div>
                          </div>
                          <div className="description">
                            <p>
                              Build or refresh in-demand skills all employers want with 40+ <a href="#" onClick={this.toggleSkillBuildersModal}>Skill Builders</a>. Boost your knowledge & skills and learn how to clearly explain and sell your skills in job interviews, networking conversations, and performance reviews.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={skillBuilders} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={
                  classNames('gallery-item',
                    {
                      'animated fadeIn': activeSlideOne === 'skillBadges',
                      'active': activeSlideOne === 'skillBadges'
                    })}>

                  <div className="container">
                    <div className="row row-eq-height">

                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Skill Badges</div>
                          </div>
                          <div className="description">
                            <p>
                              Claim <a href="#" onClick={this.toggleSkillBadgeModal}>Skill Badges</a> to help you prove your employability skills with tangible evidence others can review and endorse. Ensure employers have no doubt you’ve got the skills <Link to="/research" target="_blank" rel="noopener noreferrer">research</Link> shows they want but struggle to find in job candidates.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={skillBadges} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={
                  classNames('gallery-item',
                    {
                      'animated fadeIn': activeSlideOne === 'skillFeedback',
                      'active': activeSlideOne === 'skillFeedback'
                    })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Skill Feedback</div>
                          </div>
                          <div className="description">
                            <p>
                              Get your claimed skills recognized through the power of feedback. CareerPrepped lets you provide evidence to prove each skill you have and then get feedback from community members and <a href="#" onClick={this.toggleIndustryMentorsModal}>industry mentors</a> on how well you’ve proven them. Improve your skill evidence and increase your feedback ratings to showcase your proven skills to employers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={skillFeedback} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={
                  classNames('gallery-item',
                    {
                      'animated fadeIn': activeSlideOne === 'skillEndorsements',
                      'active': activeSlideOne === 'skillEndorsements'
                    })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Skill Endorsements</div>
                          </div>
                          <div className="description">
                            <p>
                              Collect meaningful endorsements and testimonials from people who have seen your skills in action, not just artificial endorsements of skills from your connections. Validated endorsements of specific skills with supporting evidence help you stand out and persuade employers to hire you over the competition.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={skillEndorsements} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>
            <section className="section-secure">
              <h1>Tools To Help You Secure Opportunities</h1>
              <div className="ss-navigation">
                <a
                  href="#"
                  id="careerSite"
                  data-name="activeSlideTwo"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideTwo === 'careerSite' })}>
                  Career Site
                </a>
                <a
                  href="#"
                  id="jobMatch"
                  data-name="activeSlideTwo"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideTwo === 'jobMatch' })}>
                  Job Match
                </a>
                <a
                  href="#"
                  id="jobTracker"
                  data-name="activeSlideTwo"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideTwo === 'jobTracker' })}>
                  Job Tracker
                </a>
                <a
                  href="#"
                  id="resumeBuilder"
                  data-name="activeSlideTwo"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideTwo === 'resumeBuilder' })}>
                  Resume Builder
                </a>
                <a
                  href="#"
                  id="interviewVideos"
                  data-name="activeSlideTwo"
                  onClick={this.onClickSlide}
                  className={classNames({ active: activeSlideTwo === 'interviewVideos' })}>
                  Interview Videos
                </a>
              </div>

              <div className="gallery">
                <div className={
                  classNames('gallery-item',
                    {
                      'animated fadeIn': activeSlideTwo === 'careerSite',
                      'active': activeSlideTwo === 'careerSite'
                    })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Career Site</div>
                          </div>
                          <div className="description">
                            <p>
                              Don’t settle for a traditional resume or just an online profile. Stand out from competition by demonstrating what makes you, you. Showcase yourself through your own personal website backed with multi-media evidence of your skills.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={careerSite} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideTwo === 'jobMatch',
                    'active': activeSlideTwo === 'jobMatch'
                  })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Job Match</div>
                          </div>
                          <div className="description">
                            <p>
                              Let our machine intelligence automatically match you with jobs that fit your skills from 14M+ jobs posted across 80K+ sites. No longer will you have to endlessly search job boards or get lost in networking sites. We bring the jobs to you.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={jobMatch} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideTwo === 'jobTracker',
                    'active': activeSlideTwo === 'jobTracker'
                  })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Job Tracker</div>
                          </div>
                          <div className="description">
                            <p>
                              Organize and manage your job applications in one place. Save any jobs that interests you, add your notes and store contact information. Track your progress from application to offer and accept the job offer you want.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={jobTracker} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideTwo === 'resumeBuilder',
                    'active': activeSlideTwo === 'resumeBuilder'
                  })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Resume Builder</div>
                          </div>
                          <div className="description">
                            <p>
                              Create and save unlimited resumes targeted toward specific jobs, companies, or hiring managers so you stand out with a customized resume for any opportunity you want to pursue. Easily convert your Career Site into a resume format with a button click, edit it on-the-fly, save it and use when needed.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={resumeBuilder} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideTwo === 'interviewVideos',
                    'active': activeSlideTwo === 'interviewVideos'
                  })}>

                  <div className="container">
                    <div className="row row-eq-height">
                      <div className="col-lg-6">
                        <div className="gallery-content">
                          <div className="title">
                            <div className="title-inside">Interview Videos</div>
                          </div>
                          <div className="description">
                            <p>
                              Learn to effectively respond to common interview questions across all industries and jobs, so that you nail your next interview. Videos allow you to see weak responses and strong responses with specific feedback, so you know what to do and what to avoid.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="image">
                          <img src={interviewVideos} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {showSkillBadgeModal &&
              <SkillBadgeModal
                showSkillBadgeModal={showSkillBadgeModal}
                toggleSkillBadgeModal={this.toggleSkillBadgeModal} />}

            {showIndustryMentorsModal &&
              <IndustryMentorsModal
                showIndustryMentorsModal={showIndustryMentorsModal}
                toggleIndustryMentorsModal={this.toggleIndustryMentorsModal} />}

            {showSkillBuildersModal &&
              <SkillBuildersModalContainer
                isRequesting={isRequesting}
                skillbuilders={skillBuildersData}
                showSkillBuildersModal={showSkillBuildersModal}
                toggleSkillBuildersModal={this.toggleSkillBuildersModal} />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    skillBuilders: state.skillBuilders
  };
};

const mapDispatchToProps = dispatch =>
  ({ actions: bindActionCreators({ openModal, ...skillBuildersActions }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Toolkit);
