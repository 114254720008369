import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { toastr } from 'react-redux-toastr';
import WrapperComponent from './WrapperComponent';
import ScrollToEnds from '../scrollToEnds/ScrollToEnds';

class InfiniteScroller extends Component {
  static propTypes = {
    page_count: PropTypes.number,
    callAPI: PropTypes.func, // is expected to return a promise
    page: PropTypes.number,
    onComponentUnmount: PropTypes.func,
    shouldCallAPIInitially: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
  }

  constructor(props) {
    super(props);

    this.state = {
      callingAPI: false,
      page: this.props.page,
      page_count: this.props.page_count,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    const {
      callAPI,
      shouldCallAPIInitially
    } = this.props;

    if (shouldCallAPIInitially) {
      this.setState({
        callingAPI: true
      });

      callAPI(1)
        .then(() => {
          this.setState({
            callingAPI: false,
            page_count: this.props.page_count
          });
        })
        .catch((err) => {
          this.setState({
            callingAPI: false
          });

          toastr.error(`Error in calling API: ${err}`);
        });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.page_count !== this.props.page_count)) {
      this.setState({
        page: nextProps.page,
        page_count:nextProps.page_count
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    if (this.props.onComponentUnmount)
      this.props.onComponentUnmount();
  }

  handleScroll() {
    const {
      callAPI
    } = this.props;

    const { callingAPI, page, page_count } = this.state;

    const conditionToLoadMoreItems = (
      (window.innerHeight + window.pageYOffset - 30) >= document.body.offsetHeight
    );

    if (!callingAPI && (page !== page_count) && page_count != null) {
      if (conditionToLoadMoreItems) {
        // call API
        this.setState({ callingAPI: true });

        callAPI(page + 1)
          .then(() => {
            this.setState({
              callingAPI: false,
              page: this.state.page + 1
            });
          })
          .catch((err) => {
            this.setState({ callingAPI: false });

            toastr.error(`Error in calling API: ${err}`);
          });
      }
    }
  }

  render() {
    const { callingAPI } = this.state;

    return (
      <ScrollToEnds
        childComponent={WrapperComponent}
        callingAPI={callingAPI}
        {...this.props}/>
    );
  }
}

export default InfiniteScroller;
