import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty, isUndefined } from 'lodash';

import Validator from '../../validator';
import { openModal } from '../../redux/actions/components';
import { educatorSignUp } from '../../redux/actions/registration';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import TalentDeveloperAccountForm from '../../components/skills-to-careers-coalition/TalentDeveloperAccountForm';

const participate1 = require('../../assets/images/coalition/img-participate-1.png');
const participate2 = require('../../assets/images/coalition/img-participate-2.png');
const participate3 = require('../../assets/images/coalition/img-participate-3.png');

const memberACCSC = require('../../assets/images/coalition/img-member-accsc.jpg');
const memberOBCCS = require('../../assets/images/coalition/img-member-obccs.jpg');
const memberCECU = require('../../assets/images/coalition/img-member-cecu.jpg');
const memberACTE = require('../../assets/images/coalition/img-member-acte.jpg');
const memberIAF = require('../../assets/images/coalition/img-member-iaf.jpg');
const memberDEAC = require('../../assets/images/coalition/img-member-deac.jpg');
const partBeverly = require('../../assets/images/coalition/img-part-beverlyhillsschool.jpg');
const partBlueCliff = require('../../assets/images/coalition/img-part-bluecliffcollege.jpg');
const partCACC = require('../../assets/images/coalition/img-part-capitalareacareercenter.jpg');
const partCHFS = require('../../assets/images/coalition/img-part-CFHS.jpg');
const partCSDAR = require('../../assets/images/coalition/img-part-CSD-AR.jpg');
const partCTHS = require('../../assets/images/coalition/img-part-cths.jpg');
const partDawn = require('../../assets/images/coalition/img-part-dawncareerinstitute.jpg');
const partECPI = require('../../assets/images/coalition/img-part-ecpi.jpg');
const partGilbert = require('../../assets/images/coalition/img-part-gilbertschools.jpg');
const partHCST = require('../../assets/images/coalition/img-part-HCST.jpg');
const partHeritageSW = require('../../assets/images/coalition/img-part-heritagesouthwest.jpg');
const partIMCCTC = require('../../assets/images/coalition/img-part-imcctc.jpg');
const partMadeira = require('../../assets/images/coalition/img-part-madeira.jpg');
const partMHHC = require('../../assets/images/coalition/img-part-mhcc.jpg');
const partMoisd = require('../../assets/images/coalition/img-part-moisd.jpg');
const partNorwalk = require('../../assets/images/coalition/img-part-norwalkcommunitysd.jpg');
const partNYC = require('../../assets/images/coalition/img-part-nyc.jpg');
const partOrleans = require('../../assets/images/coalition/img-part-orleanstechnicalcollege.jpg');
const partPADLICWIA = require('../../assets/images/coalition/img-part-PADLICWIA.jpg');
const partPlatt = require('../../assets/images/coalition/img-part-platt.jpg');
const partPSI = require('../../assets/images/coalition/img-part-psi.jpg');
const partStJoseph = require('../../assets/images/coalition/img-part-stjosephHS.jpg');
const partTaylor = require('../../assets/images/coalition/img-part-taylor-college.jpg');
const partTift = require('../../assets/images/coalition/img-part-tiftcountyschools.jpg');


let credentials = {
  firstName: '',
  lastName: '',
  roleType: '',
  organizationType: '',
  workEmail: '',
  alternateEmail: '',
  jobTitle: '',
  organization: '',
  coalitionMember: ''
};

class SkillsToCareersCoalition extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      credentials,
      displayAlertType: '',
      alternateEmailPopover: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onChangeCredentials = this.onChangeCredentials.bind(this);
    this.toggleRequestInvite = this.toggleRequestInvite.bind(this);
    this.toggleAlternateEmailPopover = this.toggleAlternateEmailPopover.bind(this);
  }

  toggleAlternateEmailPopover(event) {
    event.preventDefault();

    this.setState({ alternateEmailPopover: ! this.state.alternateEmailPopover });
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      firstName: ['required', 'minLength|1'],
      lastName: ['required', 'minLength|1'],
      roleType: ['required'],
      organizationType: ['required'],
      workEmail: ['required', 'email', 'workEmail'],
      alternateEmail: ['required', 'email', 'notMatch|workEmail'],
      jobTitle: ['required', 'minLength|1'],
      organization: ['required', 'minLength|1'],
      coalitionMember: ['required']
    }, credentials, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeCredentials(event) {
    const { name, value } = event.target;
    const credentials = Object.assign(
      {},
      this.state.credentials,
      { [name]: value }
    );

    this.setState({ credentials }, () => this.isValid(name));
  }

  onSubmit(event) {
    event.preventDefault();

    if (! this.isValid()) return;

    let { credentials: talentDeveloper } = this.state;

    talentDeveloper = Object.assign({}, talentDeveloper, { email: talentDeveloper.workEmail });

    delete talentDeveloper['workEmail'];

    this.props.actions.educatorSignUp(talentDeveloper)
      .then(({ registration }) => {
        let displayAlertType = 'success';

        if ((! isEmpty(registration.error)) && (! isUndefined(registration.error.errorType))) {
          credentials = this.state.credentials;
          displayAlertType = (registration.error.errorType === 'DuplicateEducatorRegistrationException') ? 'warning' : 'danger';
        }

        this.setState({ credentials, displayAlertType });
      })
      .catch((error) => {
        let displayAlertType = 'danger';

        if ((! isEmpty(error)) && (! isUndefined(error.errorType))) {
          credentials = this.state.credentials;
          displayAlertType = (error.errorType === 'DuplicateEducatorRegistrationException') ? 'warning' : displayAlertType;
        }

        this.setState({ credentials, displayAlertType });
      });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.onSubmit(event);
    }
  }

  onDismissAlert() {
    this.setState({ displayAlertType: '' });
  }

  toggleRequestInvite() {
    this.props.actions.openModal('invite-modal');
  }

  render() {
    const { registration } = this.props;
    const {
      errors,
      credentials,
      displayAlertType,
      alternateEmailPopover
    } = this.state;

    return (
      <div>
        <Helmet title="Skills to Careers Coalition"/>
        <div className="skillstocareers">
          <article className="skilltocareers_header">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>Skills to Careers Initiative</h1>
                  <p>The Skills to Careers Initiative was formed to promote and support skills-based education and hiring at scale. Supporters of this initiative have enabled the offering of a free version of CareerPrepped's Talent Developer account for career education and workforce development organizations. This enables adopting organizations to empower their learners and job seekers to make their skills visible and verifiable while automatically matching them to real jobs based on their skills.</p>
                </div>
              </div>
            </div>
          </article>
          <article className="skilltocareers_account">

            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="skilltocareers_account-header">
                    <div className="header-label">Free Talent Developer Account</div>
                    <div className="header-label-to">For Career Education and Workforce Development Organizations</div>
                  </div>
                  <div className="developer_account">
                    <TalentDeveloperAccountForm
                      errors={errors}
                      onSubmit={this.onSubmit}
                      onChange={this.onChangeCredentials}
                      onKeyPress={this.onKeyPress}
                      credentials={credentials}
                      registration={registration}
                      alternateEmailPopover={alternateEmailPopover}
                      displayAlertType={displayAlertType}
                      onDismissAlert={this.onDismissAlert}
                      toggleAlternateEmailPopover={this.toggleAlternateEmailPopover}/>

                    <div className="participate">
                      <h2>Why Participate?</h2>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="part-image">
                            <img src={participate1} width="90" alt="Participate icon" />
                          </div>
                          <p>More employers are choosing to hire based on skills instead of solely considering skill proxies such as education level and work histories.</p>
                        </div>
                        <div className="col-lg-4">
                          <div className="part-image">
                            <img src={participate2} width="90" alt="Participate icon" />
                          </div>
                          <p>Workforce entrants' struggle to identify, demonstrate and articulate their skills causing many of their skills to remain hidden and unrecognized.</p>
                        </div>
                        <div className="col-lg-4">
                          <div className="part-image">
                            <img src={participate3} width="90" alt="Participate icon" />
                          </div>
                          <p>Talent developers must empower learners and job seekers to make their skills visible & verifiable to succeed in the skills-based economy.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </article>
          <article className="skilltocareers_members">

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2>Initiative Supporters</h2>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <a href="https://www.accsc.org/" target="_blank"><img src={memberACCSC} className="img-fluid" alt="Coalition Members - ACCSC" /></a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12" style={{backgroundColor:'white'}}>
                      <a href="https://www.deac.org/" target="_blank" style={{backgroundColor:'white',display: 'block'}}><img src={memberDEAC} className="img-fluid" alt="Coalition Members - DEAC" /></a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <a href="https://scr.ohio.gov/" target="_blank"><img src={memberOBCCS} className="img-fluid" alt="Coalition Members - Ohio State Board" /></a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <a href="https://www.acteonline.org/" target="_blank"><img src={memberACTE} className="img-fluid" alt="Coalition Members - ACTE" /></a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <a href="https://www.career.org/" target="_blank"><img src={memberCECU} className="img-fluid" alt="Coalition Members - CECU" /></a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <a href="https://www.imagine-america.org/" target="_blank"><img src={memberIAF} className="img-fluid" alt="Coalition Members - IAF" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="text-center contact">
                <div className="coalition_contact">
                  <p className="mb-1">
                    Interested in supporting the Skills to Careers Initiative?
                  </p>
                  <button onClick={this.toggleRequestInvite}>Contact Us</button>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2>CareerPrepped Adoptions</h2>
                  <p>
                    Hundreds of organizations have adopted CareerPrepped to empower their learners and job seekers to make their skills visible and verifiable. Below is a sample list.
                  </p>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partBeverly} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partBlueCliff} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partCACC} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partCHFS} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partCSDAR} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partCTHS} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partDawn} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partECPI} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partGilbert} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partHCST} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partHeritageSW} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partIMCCTC} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partMadeira} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partMHHC} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partMoisd} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partNorwalk} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partNYC} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partOrleans} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partPADLICWIA} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partPlatt} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partPSI} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partStJoseph} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partTaylor} className="img-fluid" alt="Participant Logo" />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <img src={partTift} className="img-fluid" alt="Participant Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ registration }) => ({ registration });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    educatorSignUp
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillsToCareersCoalition);
