import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import Results from './Results';
import Loading from '../common/Loading';
import DeleteJobModal from './DeleteJobModal';

const sortOptions = [
  {
    value: 'relevance',
    label: 'Relevance'
  },
  {
    value: 'date',
    label: 'Date'
  }
];

const JobSearchResults = ({
  search,
  results,
  isSubmitting,
  addToBoard,
  applyToJob,
  callingAPI,
  deleteJob,
  isDeleting,
  onChangeSort,
  onConfirmDeleteJob,
  onCancelDeleteJob,
  confirmDeleteJobModalIsOpen
}) => {
  const { results: resultsArray } = results;

  return (
    <div className="mt-4">
      {/*
        Derrick(3720):
        Sorting should be at the result
        level where they can sort the
        results after the form submission.
      */}
      <div className="filterbar justify-content-end">
        <ReactSelect
          onChange={onChangeSort}
          clearable={false}
          searchable={false}
          placeholder={'Sort Search'}
          simpleValue
          value={search.sort}
          className="jobsearch-result-search"
          options={sortOptions}/>
      </div>
      <div className="jobsearch-grid">

        {resultsArray &&
          resultsArray.map((job, index)=>
            (<Results
              key={index}
              data={job}
              index={index}
              isSubmitting={isSubmitting}
              deleteJob={deleteJob}
              applyToJob={applyToJob}
              addToBoard={addToBoard}/>))}

        <div className="col-lg-12">
          {callingAPI && <Loading />}
        </div>
      </div>

      {confirmDeleteJobModalIsOpen &&
        <DeleteJobModal
          onCancel={onCancelDeleteJob}
          onConfirm={onConfirmDeleteJob}
          isDeleting={isDeleting}
          isOpen={confirmDeleteJobModalIsOpen}/>}
    </div>
  );
};

JobSearchResults.propTypes = {
  search: PropTypes.object.isRequired,
  results: PropTypes.object.isRequired,
  isSubmitting: PropTypes.object,
  addToBoard: PropTypes.func.isRequired,
  applyToJob: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  callingAPI: PropTypes.bool,
  deleteJob: PropTypes.func.isRequired,
  isDeleting: PropTypes.object.isRequired,
  onConfirmDeleteJob: PropTypes.func.isRequired,
  onCancelDeleteJob: PropTypes.func.isRequired,
  confirmDeleteJobModalIsOpen: PropTypes.bool.isRequired
};

export default JobSearchResults;
