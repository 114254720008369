export default {
  /**
   * Employment types and contract types combined and mapped in the backend
   */
  jobTypeOptions: [
    { value: '1', label: 'Full-Time' },
    { value: '2', label: 'Part-Time' },
    // below are contract types
    { value: '3', label: 'Temporary Contract' },
    { value: '4', label: 'Side Job' },
    { value: '5', label: 'Internship/Graduation Position' },
    { value: '6', label: 'Voluntary Work' },
    { value: '7', label: 'Freelance' },
    { value: '8', label: 'Apprenticeship' }
  ],

  /**
   * Job advertiser type options
   */
  jobAdvertiserTypeOptions: [
    { value: '1', label: 'Direct Employer' },
    { value: '2', label: 'Staffing/Recruitment Agency' }
  ],

  /**
   * Sorting options
   */
  sortOptions: [
    { value: 'highest', label: 'Match Score: Highest' },
    { value: 'lowest', label: 'Match Score: Lowest' },
    { value: 'newest', label: 'Date: Newest' },
    { value: 'oldest', label: 'Date: Oldest' },
    { value: 'asc', label: 'Position: A-Z' },
    { value: 'desc', label: 'Position: Z-A' }
  ]
};
