import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const headerImage = require('../../../assets/images/homepage/individual/img-individual-header.png');
const accscLogo = require('../../../assets/images/homepage/img-logo-accsc.png');
const acteLogo = require('../../../assets/images/homepage/img-logo-acte.png');

const imgJenny = require('../../../assets/images/homepage/img-testimonial-jennyj.png');
const imgJeremy = require('../../../assets/images/homepage/img-testimonial-jeremyb.png');
const imgSarah = require('../../../assets/images/homepage/img-testimonial-sarahk.png');
const imgSkillSpotlight = require('../../../assets/images/homepage/img-skill-spotlight.png');

const Talent = ({ toggleSignUpModal }) => {
  return (
    <div className="talent">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <header>
              <div className="row">
                <div className="col-lg-12">
                  <img src={headerImage} className="img-fluid"/>
                  <div className="callout mx-auto align-text-bottom">
                    <h2>
                      <span>Finally</span>, be seen for your skills and get the career you want
                    </h2>
                    <p>
                      You know what you have to offer. We help you ensure employers do too, so you won't be passed up for the career opportunities you deserve.
                    </p>
                    <div className="action-button-cont">
                      <button
                        onClick={toggleSignUpModal}
                        className="btn signup-btn">
                        Sign Up Free
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
      <article className="career-opportunities">
        <div className="container">
          <div className="col-lg-12">
            <h1>Put your skills in the spotlight and set yourself apart from the competition.</h1>
            <div className="row">
              <div className="col-lg-6">
                <ul className="fa-ul">
                  <li><span className="fa-li"><i className="far fa-arrow-alt-right" /></span>  Capture and showcase all your skills no matter how you got them - life, education, work, etc.</li>
                  <li><span className="fa-li"><i className="far fa-arrow-alt-right" /></span>Back your skills with evidence and get 360° feedback to know which skills are well proven.</li>
                  <li><span className="fa-li"><i className="far fa-arrow-alt-right" /></span>Level up your skill ratings and get endorsements and testimonials to make your skills shine.</li>
                  <li><span className="fa-li"><i className="far fa-arrow-alt-right" /></span>Build and showcase in-demand soft skills all employers want.</li>
                  <li><span className="fa-li"><i className="far fa-arrow-alt-right" /></span>Get automatically matched to live jobs that fit your skills with our AI technology.</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <img src={imgSkillSpotlight} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-12">
                <h1>Take charge of your career and future with our all-in-one career success <Link to="/toolkit">Toolkit</Link>.</h1>
              </div>
            </div>
            <div className="action-button-cont">
              <button
                onClick={toggleSignUpModal}
                className="btn signup-btn">
                Sign Up Free
              </button>
            </div>
          </div>
        </div>
      </article>
      <article className="difference">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1>
                How CareerPrepped® is different<br />
                from job boards and networking sites
              </h1>
            </div>
            <div className="col-lg-12">
              <div className="difference-item item-1">
                <div className="difference-item-content">
                  <div className="difference-item-label"><div className="arrow-right"/>Career Success Based On</div>
                  <h3>Your Skills, Potential and Drive</h3>
                  <p><strong>Not</strong> Your Education Level or Past Job Titles</p>
                  <p>
                    Job boards and networking sites focus too much on degrees, titles and experience limiting employers' understanding of who you are and what you offer. CareerPrepped helps you show your best self and stand out on proven ability and potential.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="difference-item item-2">
                <div className="difference-item-content">
                  <div className="difference-item-label"><div className="arrow-right"/>Career Success Defined By</div>
                  <h3>Your Lifelong Career Achievement</h3>
                  <p><strong>Not</strong> Your Next Job or Number of Connections</p>
                  <p>
                    Job boards and networking sites focus too much on having you search for your next job, making connections and competing for attention. CareerPrepped helps you stay current with your skills in a supportive community and matches you with job opportunities throughout your entire career.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="endorsed-by">
        <h2>Endorsed and adopted by</h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col">
                  <div className="endorsed-card">
                    <a href="https://acteonline.org" target="_blank">
                      <img src={acteLogo} className="img-fluid" alt="ACTE - Association for Career and Technical Education"/>
                    </a>
                  </div>
                </div>
                <div className="col">
                  <div className="endorsed-card">
                    <a href="https://accsc.org" target="_blank">
                      <img src={accscLogo} className="img-fluid" alt="ACCSC - Accrediting Commision of Career Schools and Colleges"/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="tagline"><span>To improve workforce readiness and enable skills-based hiring</span></div>
            </div>
          </div>
        </div>
      </article>
      <article className="testimonials">
        <h1>What Community Members Are Saying</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xl-4">
              <div className="testimonial-item">
                <div className="testimonial-item_profile">
                  <img src={imgSarah} className="img-fluid" alt="CareerPrepped helped prove skills"/>
                </div>
                <div className="testimonial-item_quote">
                  CareerPrepped helped me realize if I can prove my skills, it doesn't matter how I got them. The feedback tool helped me understand my strengths, how others view my skills, and how I can improve so I can stand out when applying for jobs.
                </div>
                <div className="testimonial-item_author">
                  Sarah K.
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xl-4">
              <div className="testimonial-item">
                <div className="testimonial-item_profile">
                  <img src={imgJeremy} className="img-fluid" alt="CareerPrepped is a one-stop shop"/>
                </div>
                <div className="testimonial-item_quote">
                  I feel like I found a one-stop shop to manage my career. I can capture my skills, show my work samples, get feedback from the community, get job matches, track my job search progress, and more - it's super convenient.
                </div>
                <div className="testimonial-item_author">
                  Jeremy B.
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xl-4">
              <div className="testimonial-item">
                <div className="testimonial-item_profile">
                  <img src={imgJenny} className="img-fluid" alt="Careerprepped - Life experiences matter"/>
                </div>
                <div className="testimonial-item_quote">
                  I realized the skills I've developed from life experience matter. The tools are great but the biggest surprise for me was how much my confidence has been boosted. I feel I have more control over my story, my career, and my future.
                </div>
                <div className="testimonial-item_author">
                  Jenny J.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="action-button-cont">
          <button
            onClick={toggleSignUpModal}
            className="btn signup-btn">
            Sign Up Free
          </button>
        </div>
      </article>
    </div>
  );
};

Talent.propTypes = {
  toggleSignUpModal: PropTypes.func.isRequired
};

export default pure(Talent);
