import PropTypes from 'prop-types';
import React from 'react';
import SidebarUserInfo from '../../../components/common/SidebarUserInfo';
import DiscussionsPostFormContainer from '../../../containers/skill-builders/discussions/DiscussionsPostFormContainer';

const FormAndSideBar = ({
  isAuthenticated,
  profile,
  route,
  unit
}) => {
  return(
    <div>
      {
        isAuthenticated &&
          <div className="row">
            <div className="col-lg-3">
              <SidebarUserInfo profile={profile}/>
            </div>

            <DiscussionsPostFormContainer route={route} unit={unit}/>
          </div>
      }
    </div>
  );
};

FormAndSideBar.propTypes = {
  unit: PropTypes.object.isRequired,
  route: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object
};

export default FormAndSideBar;
