import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Prompt } from 'react-router-dom';

import { toastr } from 'react-redux-toastr';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { difference } from '../../utils';
import { withHooks } from '../../utils/withHooks';

import * as componentsActions from '../../redux/actions/components';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as routerActions from '../../redux/actions/router';
import * as authenticationActions from '../../redux/actions/authentication';

import DashboardForumForm from '../../components/dashboard/DashboardForumForm';
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    value:'1',
    icon:'fa fa-globe'
  },
  {
    title:'Followers Only',
    value:'2',
    icon:'fa fa-user-circle-o'
  },
  {
    title:'Only Me',
    value:'0',
    icon:'fa fa-lock'
  },

];

class DashboardPostFormContainer extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    route: PropTypes.object,
    router: PropTypes.object,
    wallPost: PropTypes.object,
    storedAction: PropTypes.string,
    isHookedToARoute: PropTypes.object,
    onCancelEdit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    onSave: PropTypes.func,
    isEdit: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      nextLocation: null,
      showCustomDialog: false,
      errors: {},
      postVisibilityDropdown: false,
      isCancelModalOpen: false,
      attachment: {},
      plainText: '',
      imagePreviewUrl: '',
      wallPost: { post: '', attachment: {}, permissions: '1' }
    };

    this.onSave = this.onSave.bind(this);
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.handleHTMLChange = this.handleHTMLChange.bind(this);
    this.togglePostVisibilityDropdown = this.togglePostVisibilityDropdown.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    //    const { router, route } = this.props;
    //
    //    router.setRouteLeaveHook(route, this.routerWillLeave);
    //    window.onbeforeunload = this.routerWillLeave;
  }

  componentDidUpdate(){
    const { wallPost: wallPostInState, imagePreviewUrl, attachment } = this.state;
    const hasChanged = difference({ post: '', attachment: {}, permissions: '1' }, wallPostInState);
    const hasChangedAttachment = difference({}, attachment);
    const hasChangedImagePreview = imagePreviewUrl !== '';

    if ((!isEmpty(hasChanged) || !isEmpty(hasChangedAttachment) || hasChangedImagePreview) && !this.props.isHookedToARoute.status) {
      return this.props.actions.setRouterHookedToARoute('homeagep');
    }
    if( isEmpty(hasChanged) && isEmpty(hasChangedAttachment) && !hasChangedImagePreview && this.props.isHookedToARoute.status){
      this.props.actions.removeRouterHookedToARoute();
    }
  }

  componentWillUnmount(){
    window.onbeforeunload = null;
    const { actions, isHookedToARoute } = this.props;

    if(isHookedToARoute.status)
      actions.removeRouterHookedToARoute();
  }

  routerWillLeave(nextLocation) {
    const { wallPost: wallPostInState, nextLocation: nextLocationInState, imagePreviewUrl, attachment } = this.state;
    const hasChanged = difference({ post: '', attachment: {}, permissions: '1' }, wallPostInState);
    const hasChangedAttachment = difference({}, attachment);
    const hasChangedImagePreview = imagePreviewUrl !== '';

    if ((!isEmpty(hasChanged) || !isEmpty(hasChangedAttachment) || hasChangedImagePreview) && wallPostInState.post !== '<p><br></p>' && nextLocationInState === null) {

      this.setState({
        showCustomDialog: true,
        nextLocation: nextLocation.pathname
      });
      return false;
    }else{
      this.setState({
        nextLocation: null
      });
      return true;
    }
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }


  onSelectVisibilty(id){
    return () => {
      const wallPostObject = { ...this.state.wallPost, permissions:id};

      this.setState({
        wallPost:wallPostObject,
        postVisibilityDropdown:false
      });
    };
  }

  togglePostVisibilityDropdown(){
    this.setState({
      postVisibilityDropdown: !this.state.postVisibilityDropdown
    });
  }

  onDropFile(files) {
    const reader = new FileReader();

    if((files.length !== 0) && (files[0].size > 8000000))
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    if (files.length !== 0) {
      reader.onload = () => {
        const dataUrl = reader.result;

        this.setState({
          attachment: files[0],
          imagePreviewUrl: dataUrl
        });
      };
    }

    reader.readAsDataURL(files[0]);
  }

  handleHTMLChange(content, delta, source, editor){
    let { wallPost } = this.state;

    wallPost.post = content;
    this.setState({
      wallPost,
      plainText:  editor.getText(content).trim()
    });
  }

  handleResponse() {
    this.setState({
      wallPost: { post: '', attachment: {}, permissions: '1' },
      attachment: {},
      imagePreviewUrl: ''
    });
  }

  onCancel() {
    this.setState({
      attachment: {},
      imagePreviewUrl: ''
    });
  }

  onCancelConfirm(){
    this.props.onCancelEdit();
  }

  onToggleCancelModal(){
    this.setState({
      isCancelModalOpen: !this.state.isCancelModalOpen
    });
  }

  onSave(event) {
    event.preventDefault();
    const { isEdit, actions, onSave } = this.props;
    let { wallPost, attachment, plainText } = this.state;

    if(plainText === '' && isEmpty(attachment)){
      return actions.openModal('emptyPostModal');
    }

    let formData = new FormData();
    formData.append('post', plainText === '' ? '<br>' : wallPost.post);

    if (!isEmpty(attachment))
      formData.append('attachment', attachment);

    formData.append('permissions', wallPost.permissions);

    if (isEdit) {
      return onSave(formData);
    }

    actions.saveWallPost(formData).then(() => this.handleResponse());
  }

  render() {
    const {
      wallPost,
      postVisibilityDropdown,
      errors,
      showCustomDialog,
      isCancelModalOpen,
      imagePreviewUrl
    } = this.state;
    const { isSubmitting, isHookedToARoute } = this.props;

    const buttonText = isSubmitting ? 'Posting...': 'Post';

    const dropdownButton = find(visibilityOptionsArray, option =>
      option.value == wallPost.permissions);

    return (
      <div className="col-lg-9">
        <div className="forum-enter">
          <div className="top">
            <div className="form-inline">
              <Prompt
                when={isHookedToARoute.status}
                message="Are you sure you want to leave this page? Any changes may be unsaved."/>
              <DashboardForumForm
                name="post"
                error={errors.post}
                value={wallPost.post}
                imagePreviewUrl={imagePreviewUrl}
                onDropFile={this.onDropFile}
                onCancel={this.onCancel}
                handleHTMLChange={this.handleHTMLChange}/>
            </div>
          </div>
          <div className="bottom">
            <Dropdown
              className="dropdown"
              isOpen={postVisibilityDropdown}
              toggle={this.togglePostVisibilityDropdown}>
              <DropdownToggle
                caret
                color="tertiary"
                className="btn btn-tertiary activity-filter-button">
                {dropdownButton == null ? 'Who can see this post' : <span><i className={dropdownButton.icon}/> {dropdownButton.title}</span>}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu">
                {visibilityOptionsArray.map((option,index) =>
                  (<a className="dropdown-item" key={index} onClick={this.onSelectVisibilty(option.value)}>
                    <i className={option.icon} style={{'width':'25px','textAlign':'center'}}/> {option.title}
                  </a>))}
              </DropdownMenu>
            </Dropdown>
            <button
              style={{marginLeft:8}}
              type="submit"
              className="btn btn-primary"
              onClick={this.onSave}
              disabled={isSubmitting}>
              {buttonText}
            </button>
          </div>
        </div>

        {
          showCustomDialog &&
            <Modal
              size="lg"
              className="modal-margin-top"
              isOpen={showCustomDialog}
              backdrop="static"
              toggle={this.closeCustomDialog}>
              <ModalHeader
                className="no-border"
                toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
              <ModalBody>
                <p>Are you sure you want to leave this page? Any changes may be unsaved.</p>
              </ModalBody>
              <ModalFooter className="no-border">
                <button
                  onClick={this.closeCustomDialog}
                  className="btn btn-secondary float-left">
                  Stay
                </button>

                <button
                  onClick={this.onConfirmLeaveRoute}
                  className="btn btn-primary">
                  Yes, Leave
                </button>
              </ModalFooter>
            </Modal>
        }

        {
          isCancelModalOpen &&
            <Modal
              backdrop="static"
              className="modal-margin-top"
              isOpen={isCancelModalOpen}
              toggle={this.onToggleCancelModal}>
              <ModalHeader
                className="no-border"
                toggle={this.onToggleCancelModal}>
                Discard you changes
              </ModalHeader>
              <ModalBody>
                Are you sure you want to discard your changes?
              </ModalBody>
              <ModalFooter className="no-border">
                <button
                  onClick={this.onToggleCancelModal}
                  className="btn btn-secondary">
                  No
                </button>

                <button
                  onClick={this.onCancelConfirm}
                  className="btn btn-primary">
                  Discard Changes
                </button>
              </ModalFooter>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isHookedToARoute: state.router.isHookedToARoute,
    storedAction: state.router.storedAction,
    isSubmitting: state.wallPosts.isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    wallPostActions,
    componentsActions,
    routerActions,
    authenticationActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(DashboardPostFormContainer));
