import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEqual } from 'lodash';

import Select from 'react-select';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { AsyncPaginate } from 'react-select-async-paginate';

import Button from '../../components/common/Button';
import CompareJobsModal from '../../components/jobs/CompareJobsModal';
import JobMatchesResults from '../../components/jobs/JobMatchesResults';
import InfiniteScroller from '../common/infinite-scroll/InfiniteScroller';
import LocationFilterSelectOption from '../../components/jobs/LocationFilterSelectOption';

import { openModal } from '../../redux/actions/components';
import { toggleJobMatchSettingsInitialSearchRequest } from '../../redux/actions/job-match-settings';

import {
  resetJobMatches,
  addJobsToTracker,
  requestJobMatches,
  toggleSortFilter,
  toggleJobMatchesFilter,
  requestJobMatchesCities,
  toggleSelectAllJobMatches,
  toggleJobMatchesLocationFilter
} from '../../redux/actions/job-matches';

import { selectModal } from '../../redux/selectors/components';

import { selectJobMatchSettingsObject } from '../../redux/selectors/job-match-settings';

import {
  selectJobMatchesList,
  selectJobMatchesFilters,
  selectSelectedJobMatches,
  selectIsRequestingJobMatches,
  selectIsSelectedAllJobMatches,
  selectIsSubmittingJobMatch,
  selectJobMatchesPaginationData,
  selectJobMatchesCities,
  selectJobMatchesCitiesPaginationData
} from '../../redux/selectors/job-matches';

import jobsConstants from '../../constants/jobs';

const colourStyles = {
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#4b4f56',
      backgroundColor: isFocused ? '#B2D4FF' : isSelected ? '#e7f1fc' : 'transparent'
    };
  }
};

class JobMatchesList extends Component {
  constructor(props) {
    super(props);

    this.callAPI = this.callAPI.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSaveJobs = this.onSaveJobs.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onChangeSort = this.onChangeSort.bind(this);
    this.onSelectAllJobs = this.onSelectAllJobs.bind(this);
    this.onChangeFilters = this.onChangeFilters.bind(this);
    this.onClickFilterJobs = this.onClickFilterJobs.bind(this);
    this.onChangeLocationFilters = this.onChangeLocationFilters.bind(this);
  }

  componentDidMount() {
    if (!this.props.jobMatchSettings.initialSearchRequest) {
      this.props.actions.resetJobMatches();
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, jobMatchSettings } = this.props;
    const isEqualJobMatchSettings = isEqual(jobMatchSettings, prevProps.jobMatchSettings);

    if (!isEqualJobMatchSettings && (jobMatchSettings.status) && (!jobMatchSettings.initialSearchRequest)) {
      actions.resetJobMatches();
      this.callAPI(1);
    }
  }

  onChangeFilters(filterValue, { name: filterName }) {
    this.props.actions.toggleJobMatchesFilter(filterName, filterValue);
  }

  async onClickFilterJobs() {
    const { sort, filters, actions } = this.props;

    actions.resetJobMatches();
    await actions.requestJobMatches(1, sort, filters);
  }

  onChangeLocationFilters(filterValues) {
    const isRemoteEnabled = filterValues.some(item => item.value === 'remoteJob');

    if (isRemoteEnabled && filterValues.length > 2) {
      return;
    }

    if (!isRemoteEnabled && filterValues.length > 1) {
      return;
    }

    this.props.actions.toggleJobMatchesLocationFilter(filterValues);
  }

  async onChangeSort(sort) {
    const { actions } = this.props;

    actions.resetJobMatches();
    actions.toggleSortFilter(sort);
    await actions.requestJobMatches(1, sort);
  }

  callAPI(pageNo) {
    const { actions, sort } = this.props;

    if (pageNo !== 1) {
      actions.toggleJobMatchSettingsInitialSearchRequest(true);
    }

    return actions.requestJobMatches(pageNo, sort, null);
  }

  onSelectAllJobs() {
    this.props.actions.toggleSelectAllJobMatches();
  }

  openModal(event) {
    const { target: { id } } = event;

    this.props.actions.openModal(id);
  }

  async onSaveJobs() {
    const { actions, selectedJobMatches } = this.props;
    const selectedCount = selectedJobMatches.length;

    let toastrType = 'success';
    let successMessage = selectedCount === 1 ?
      'This job was successfully saved! See all your saved jobs in "Job Tracker".' :
      'These jobs were successfully saved! See all your saved jobs in "Job Tracker".';

    await actions.addJobsToTracker({ jobMatchesIds: selectedJobMatches });

    if (this.props.allDuplicatesSubmitted) {
      toastrType = 'warning';
      successMessage = 'You\'ve already saved these jobs. See all your saved jobs in "Job Tracker".';
    }

    toastr[toastrType]('', successMessage);
  }

  async loadOptions(search, loadedOptions, { page }) {
    let endpoint = `jobs/cities?page=${page}`;

    if (search) {
      endpoint = `${endpoint}&query=${search}`;
    }

    await this.props.actions.requestJobMatchesCities(endpoint);

    return {
      options: this.props.cities,
      hasMore: this.props.citiesPaginationData.pageCount > page,
      additional: { page: page + 1 }
    };
  }

  render() {
    const {
      sort,
      modal,
      filters,
      jobMatches,
      isRequesting,
      isSubmitting,
      paginationData,
      selectedJobMatches,
      isSelectedAllJobMatches
    } = this.props;

    const isCompareJobsModalOpen = modal === 'compare-jobs';

    return (
      <div>
        <nav className="jobmatch-top-filters">
          <div className="d-flex jobmatch-top-left">
            <Select
              isClearable
              name="jobType"
              isSearchable={false}
              options={jobsConstants.jobTypeOptions}
              value={filters.jobType}
              placeholder="Job Type"
              onChange={this.onChangeFilters}
              classNamePrefix="filter-dropdown"/>
            <AsyncPaginate
              isMulti
              name="jobLocation"
              additional={{ page: 1 }}
              isSearchable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              loadOptions={this.loadOptions}
              styles={colourStyles}
              className="multiselect-container"
              components={{Option: LocationFilterSelectOption}}
              value={filters.jobLocation}
              placeholder="Work Location"
              onChange={this.onChangeLocationFilters}
              classNamePrefix="filter-dropdown"/>
            <Select
              isClearable
              isSearchable={false}
              name="jobAdvertiserType"
              placeholder="Job Advertiser Type"
              options={jobsConstants.jobAdvertiserTypeOptions}
              value={filters.jobAdvertiserType}
              onChange={this.onChangeFilters}
              classNamePrefix="filter-dropdown"/>
            <Button
              onClick={this.onClickFilterJobs}
              buttonClass="btn btn-primary"
              buttonText="Filter Jobs"
              disabled={isRequesting || isSubmitting}/>
          </div>

          <span
            id="job-match-settings-modal"
            onClick={this.openModal}
            className="btn btn-secondary">
            <i className="fas fa-cog"/> Settings
          </span>
        </nav>
        <nav className="filterbar justify-content-between filterbar-jobcenter">
          <div className="d-flex align-items-center">
            <div className="form-group form-check mr-2">
              <input
                type="checkbox"
                onChange={this.onSelectAllJobs}
                checked={isSelectedAllJobMatches}
                className="form-check-input"
                id="select-all-jobs" />
              <label
                className="form-check-label"
                htmlFor="select-all-jobs">
                Select All
              </label>
            </div>
            <Button
              buttonId="compare-jobs"
              onClick={this.openModal}
              buttonClass="btn btn-primary"
              buttonText="Compare Jobs"
              disabled={selectedJobMatches.length === 0}/>
            <Button
              buttonClass="btn btn-primary"
              buttonText={`${isSubmitting ? 'Saving...' : 'Save Jobs'}`}
              onClick={this.onSaveJobs}
              disabled={isRequesting || isSubmitting || (selectedJobMatches.length === 0)}/>
          </div>

          <Select
            name="sort"
            isSearchable={false}
            options={jobsConstants.sortOptions}
            value={sort}
            className="mr-0"
            onChange={this.onChangeSort}/>
        </nav>

        <InfiniteScroller
          callAPI={this.callAPI}
          shouldCallAPIInitially
          component={JobMatchesResults}
          page_count={paginationData.pageCount}
          page={paginationData.page}
          wallPosts={jobMatches}
          isLoaderInternal/>

        {isCompareJobsModalOpen && <CompareJobsModal/>}
      </div>
    );
  }
}

JobMatchesList.propTypes = {
  modal: PropTypes.string,
  sort: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  jobMatches: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  jobMatchSettings: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  paginationData: PropTypes.object.isRequired,
  selectedJobMatches: PropTypes.array.isRequired,
  allDuplicatesSubmitted: PropTypes.bool.isRequired,
  citiesPaginationData: PropTypes.object.isRequired,
  isSelectedAllJobMatches: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  modal: selectModal(state),
  jobMatches: selectJobMatchesList(state),
  isSubmitting: selectIsSubmittingJobMatch(state),
  isRequesting: selectIsRequestingJobMatches(state),
  selectedJobMatches: selectSelectedJobMatches(state),
  jobMatchSettings: selectJobMatchSettingsObject(state),
  isSelectedAllJobMatches: selectIsSelectedAllJobMatches(state),
  paginationData: selectJobMatchesPaginationData(state),
  sort: state.jobMatches.sort,
  filters: selectJobMatchesFilters(state),
  cities: selectJobMatchesCities(state),
  citiesPaginationData: selectJobMatchesCitiesPaginationData(state),
  allDuplicatesSubmitted: state.jobMatches.allDuplicatesSubmitted
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    resetJobMatches,
    addJobsToTracker,
    requestJobMatches,
    toggleSelectAllJobMatches,
    toggleSortFilter,
    toggleJobMatchesFilter,
    requestJobMatchesCities,
    toggleJobMatchesLocationFilter,
    toggleJobMatchSettingsInitialSearchRequest
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobMatchesList);
