import React from 'react';

import { Link } from 'react-router-dom';

const SkillsSection = () => {
  return (
    <div className="dashboard-quick-links-container mb-3">
      <div className="dashboard-quick-links-header">
        Skills
      </div>
      <div className="dashboard-quick-links-menu">
        <ul>
          <li>
            <h4>
              <Link to="/skill-builders">Skill Builders</Link>
            </h4>
            <p>Boost essential skills and claim Skill Badges.</p>
          </li>
          <li>
            <h4>
              <Link to="/myskills">Claimed Skills</Link>
            </h4>
            <p>Manage your skills and badges, level them up with feedback & endorsements.</p>
          </li>
          <li>
            <h4><Link to="/portfolio">Portfolio</Link></h4>
            <p>Save and manage your portfolio files.</p>
          </li>
          <li>
            <h4><Link to="/myfeedback">Feedback</Link></h4>
            <p>See feedback & manage requests.</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SkillsSection;
