import PropTypes from 'prop-types';
import React from 'react';

import ShareModal from '../../../containers/skill-builders/discussions/ShareModal';
import PostCardContainer from '../../../containers/skill-builders/discussions/PostCardContainer';
import Loading from '../../common/Loading';

import classNames from 'classnames';

const PostCardRenderer = (props) => {
  const postStyles = classNames({
    'col-lg-9': props.isAuthenticated,
    'offset-lg-3': props.isAuthenticated,
    'col-lg-12': !props.isAuthenticated
  });

  return(
    <div className="dashboard-activity" style={{ marginTop: 10 }}>
      <div className="row">
        <div className={postStyles}>
          {props.discussions.map(discussion =>
            (<PostCardContainer
              key={discussion.id}
              unit={props.unit}
              discussion={discussion}/>))}

          <div style={{ paddingTop: 20, paddingBottom: 20 }}>
            {props.callingAPI && <Loading />}
          </div>

          {Object.keys(props.discussionShare).length > 0 && <ShareModal/>}
        </div>
      </div>
    </div>
  );
};

PostCardRenderer.propTypes = {
  unit: PropTypes.object.isRequired,
  discussions: PropTypes.array.isRequired,
  discussionShare: PropTypes.object.isRequired,
  callingAPI: PropTypes.bool,
  isAuthenticated: PropTypes.bool
};

export default PostCardRenderer;
