import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateProfile } from '../../redux/actions/profile';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';

import MyCareerPreppedStatus from './MyCareerPreppedStatus';
import JobsSection from '../../components/dashboard/JobsSection';
import SkillsStatus from '../../components/dashboard/SkillsStatus';
import SkillsSection from '../../components/dashboard/SkillsSection';
import DashboardAlert from '../../components/dashboard/DashboardAlert';
import PopularHelpTopics from '../../components/dashboard/PopularHelpTopics';

const transitionClasses = {
  exit: 'animated',
  exitActive: 'animate__slideOutUp'
};

const defaultAvatar = require('../../assets/images/avatar.jpg');
const defaultWebpageBackground = require('../../assets/images/profile-default-bg.png');

const HomePage = ({ actions, profile, isRequestingProfile }) => {
  function onDismissBanner(event) {
    event.preventDefault();

    actions.updateProfile(profile.id, { dismissBanner: 1 });
  }

  const isActiveBanner = profile && !!profile.dismissBanner;
  const firstName = profile && profile.name && profile.name.split(' ')[0];

  const backgroundImage = profile && profile.background !== '' ?
    profile.background : defaultWebpageBackground;

  const avatar = profile && profile.avatar !== '' ?
    profile.avatar : defaultAvatar;

  return (
    <div className="dashboard-content">
      <Helmet title="Home" />
      <Container>
        <Row>
          <Col>
            <h1 className="dashboard-header">Hi {firstName}!</h1>
          </Col>
        </Row>

        <CSSTransition
          in={((!isRequestingProfile) && (!isActiveBanner))}
          timeout={200}
          classNames={transitionClasses}
          unmountOnExit>
          <DashboardAlert profile={profile} onDismissBanner={onDismissBanner} />
        </CSSTransition>

        <Row>
          <Col>
            <div className="dashboard-cp-status-container">
              <MyCareerPreppedStatus />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <div className="dashboard-profile-card">
              <div
                className="dashboard-profile-bg"
                style={{ backgroundImage: `url("${backgroundImage}")` }} />
              <div className="dashboard-profile-info">
                <div className="dashboard-profile-avatar">
                  <Link to={`/cp/${profile.vanity}`}>
                    <img className="img-fluid" src={avatar} />
                  </Link>
                </div>
                <h4>{profile.name}</h4>
                <p>{profile.headline}</p>
                <Link to={`/cp/${profile.vanity}`}>{`${window.location.host}/cp/${profile.vanity}`}</Link>
              </div>
              <div className="dashboard-profile-stats-container mb-3">
                <Row>
                  <Col>
                    <div className="dashboard-profile-stat">
                      <h4>
                        <Link to={`/cp/${profile.vanity}/following`}>
                          {profile.followingCount}
                        </Link>
                      </h4>
                      <span>Following</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="dashboard-profile-stat">
                      <h4>
                        <Link to={`/cp/${profile.vanity}/followers`}>
                          {profile.followersCount}
                        </Link>
                      </h4>
                      <span>Followers</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="dashboard-profile-stat">
                      <h4>
                        <Link to="/portfolio">{profile.filesCount}</Link>
                      </h4>
                      <span>Portfolio</span>
                    </div>
                  </Col>
                </Row>
              </div>

            </div>

            <SkillsStatus />
          </Col>

          {/* Career Site Link */}
          <Col>
            <Row>
              <Col>
                <div className="dashboard-card mb-4">
                  <div className="dashboard-card-content-w-icon">
                    <div className="d-flex align-items-center">
                      <div className="dashboard-icon-background primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM15 18H4V14H15V18ZM15 13H4V9H15V13ZM20 18H16V9H20V18Z" fill="white"/>
                        </svg>
                      </div>
                      <div className="dashboard-card-info">
                        <h3><Link to={`/cp/${profile.vanity}`}>Career Site</Link></h3>
                        <p className="mb-0">Present yourself professionally with current info about your skills and qualifications.</p>
                      </div>
                    </div>
                    <div
                      className="dashboard-icon-background secondary">
                      <Link to={`/cp/${profile.vanity}`}>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.58984 16.34L13.1698 11.75L8.58984 7.16L9.99984 5.75L15.9998 11.75L9.99984 17.75L8.58984 16.34Z" fill="#749BBF"/>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {/* Links */}
              <Col>
                <Row className="mb-4">
                  {/* Skills Links */}
                  <Col md="6">
                    <SkillsSection />
                  </Col>
                  {/* Jobs Links */}
                  <Col md="6">
                    <JobsSection />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="mt-0 mb-4" />
            <PopularHelpTopics/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

HomePage.propTypes = {
  actions: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isRequestingProfile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ profile: { data: profile, isRequesting: isRequestingProfile } }) =>
  ({ profile, isRequestingProfile });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ updateProfile }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
