import { CALL_API } from '../../middlewares/api';

import {
  BUY_SUBSCRIPTION_REQUEST,
  BUY_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION_FAILURE,
} from '../../constants';

export const buySubscriptionPlan = (data) => {
  const endpoint = 'checkout/transactions';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        BUY_SUBSCRIPTION_REQUEST,
        BUY_SUBSCRIPTION_SUCCESS,
        BUY_SUBSCRIPTION_FAILURE,
      ],
    },
  };
};
