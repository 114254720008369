import React from 'react';

import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { UncontrolledTooltip } from 'reactstrap';

const industryOptions = [
  {
    value: 24,
    label: 'Accountancy'
  },
  {
    value: 6,
    label: 'Administration'
  },
  {
    value: 45,
    label: 'Advertising'
  },
  {
    value: 26,
    label: 'Animal Care'
  },
  {
    value: 37,
    label: 'Arts and Entertainment'
  },
  {
    value: 28,
    label: 'Banking'
  },
  {
    value: 47,
    label: 'Business & Consumer Services'
  },
  {
    value: 25,
    label: 'Catering'
  },
  {
    value: 31,
    label: 'Cleaning'
  },
  {
    value: 35,
    label: 'Consulting'
  },
  {
    value: 7,
    label: 'Construction'
  },
  {
    value: 50,
    label: 'Construction & Skilled Trades'
  },
  {
    value: 39,
    label: 'Customer Service'
  },
  {
    value: 11,
    label: 'Design'
  },
  {
    value: 12,
    label: 'Education'
  },
  {
    value: 34,
    label: 'Engineering'
  },
  {
    value: 36,
    label: 'Farming and Agriculture'
  },
  {
    value: 27,
    label: 'Finance'
  },
  {
    value: 80,
    label: 'Gig'
  },
  {
    value: 20,
    label: 'Health'
  },
  {
    value: 53,
    label: 'Healthcare'
  },
  {
    value: 48,
    label: 'Hospitality'
  },
  {
    value: 8,
    label: 'Human Resources'
  },
  {
    value: 23,
    label: 'IT'
  },
  {
    value: 40,
    label: 'Insurance'
  },
  {
    value: 21,
    label: 'Legal'
  },
  {
    value: 29,
    label: 'Logistics'
  },
  {
    value: 17,
    label: 'Management'
  },
  {
    value: 42,
    label: 'Manufacturing'
  },
  {
    value: 14,
    label: 'Marketing'
  },
  {
    value: 51,
    label: 'Marketing & Advertising'
  },
  {
    value: 15,
    label: 'Media'
  },
  {
    value: 38,
    label: 'Military'
  },
  {
    value: 46,
    label: 'No Discipline'
  },
  {
    value: 0,
    label: 'Other Professions'
  },
  {
    value: 41,
    label: 'PR'
  },
  {
    value: 19,
    label: 'Property'
  },
  {
    value: 44,
    label: 'Policing'
  },
  {
    value: 33,
    label: 'Public Sector'
  },
  {
    value: 10,
    label: 'Retail'
  },
  {
    value: 16,
    label: 'Sales'
  },
  {
    value: 18,
    label: 'Science'
  },
  {
    value: 52,
    label: 'Science & Engineering'
  },
  {
    value: 13,
    label: 'Security'
  },
  {
    value: 22,
    label: 'Skilled Trades'
  },
  {
    value: 32,
    label: 'Social Care'
  },
  {
    value: 43,
    label: 'Sport and Fitness'
  },
  {
    value: 30,
    label: 'Third Sector'
  },
  {
    value: 49,
    label: 'Transportation'
  },
  {
    value: 9,
    label: 'Travel'
  }
];

const JobSearchbar = ({
  search,
  onChange,
  onSubmit,
  isFinding,
  errors,
  stateId,
  loadOptions,
  onChangeDropdown,
  toggleRemoteJobs,
  onChangeIndustry,
  onClearKeywords,
  onClickReset
}) => {
  return (
    <div>
      <div className="jobboard-bar needs-validation">
        <div className="row">
          <div className="col-lg-4 mb-lg-0 pr-lg-1">
            <div className="form-group">
              <label className="form-control-label mb-1">What</label>
              <input
                type="text"
                name="query"
                autoFocus
                className="form-control"
                value={search.query}
                onChange={onChange}
                placeholder="Job title, keywords or company name"/>
              {(search.query.length !== 0) &&
                <span
                  className="far fa-times"
                  onClick={onClearKeywords}
                  style={{
                    position: 'absolute',
                    zIndex: 9,
                    right: 15,
                    top: 41,
                    cursor: 'pointer'
                  }}/>}
              {errors.query && <div className="invalid-feedback">{errors.query}</div>}
            </div>
          </div>
          <div className="col-lg-3 pr-lg-1 pl-lg-1">
            <div className="form-group">
              <label className="form-control-label mb-1" htmlFor="sort">Find Jobs by Industry</label>
              <ReactSelect
                onChange={onChangeIndustry}
                isClearable
                searchable={false}
                simpleValue
                value={search.industry}
                options={industryOptions} />
            </div>
          </div>

          <div className="col-lg-3 pr-lg-1 pl-lg-1">
            <div className="form-group mb-0">
              <label className="form-control-label mb-1">Location</label>
              <AsyncPaginate
                cacheOptions
                isSearchable
                name="state"
                isClearable={false}
                additional={{ page: 1, name: 'state' }}
                loadOptions={loadOptions}
                placeholder="State"
                hideSelectedOptions={false}
                value={search.state}
                onChange={onChangeDropdown} />
            </div>
          </div>
          <div className="col-lg-2 pr-lg-1 pl-lg-1">
            <div className="form-group">
              <label className="form-control-label mb-1">&nbsp;</label>
              <AsyncPaginate
                cacheOptions
                isSearchable
                name="city"
                cacheUniqs={[stateId]}
                additional={{ page: 1, name: 'city' }}
                isClearable={false}
                loadOptions={loadOptions}
                placeholder="City"
                hideSelectedOptions={false}
                value={search.city}
                onChange={onChangeDropdown} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-xs-6 col-sm-6 col-6">
            <div className="form-check mt-2">
              <div className="d-flex flex-row">
                <div>
                  <input
                    type="checkbox"
                    onChange={toggleRemoteJobs}
                    checked={search.remoteJobs}
                    className="form-check-input"
                    id="remote-jobs" />
                  <label
                    className="form-check-label"
                    htmlFor="remote-jobs">
                    Search remote jobs only
                  </label>
                  <i
                    id="tooltip-remote-jobs"
                    className="fa-sm ml-1 fas fa-question-circle clickable text-orange"/>
                  <UncontrolledTooltip
                    placement="top"
                    target="tooltip-remote-jobs"
                    trigger="click">
                    When checked, this option prioritizes remote jobs. Any selected State and City will be ignored to focus on finding remote opportunities. Uncheck to include location preferences in your job search.
                  </UncontrolledTooltip>
                </div>
                {/*
                  Derrick(3720) : this is an option for
                  future implentation, added for placement only

                <div className="pl-5">
                  <input
                    type="checkbox"
                    // onChange={toggleRemoteJobs}
                    // checked={search.remoteJobs}
                    className="form-check-input"
                    id="match-notify" />
                  <label
                    className="form-check-label"
                    htmlFor="match-notify">
                    Send me these matches daily
                  </label>
                  <i
                    id="tooltip-remote-jobs"
                    className="fa-sm ml-1 fas fa-question-circle clickable text-orange"/>
                  <UncontrolledTooltip
                    placement="top"
                    target="tooltip-remote-jobs"
                    trigger="click">
                    When checked, this option sends you matches daily as result match your criteria.
                  </UncontrolledTooltip>
                </div>
                */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xs-6 col-sm-6 col-6">
            <div className="float-right">
              <a href="#" className="btn mr-1 text-orange" onClick={onClickReset}>Reset</a>
              <button
                type="submit"
                name="button"
                className="btn btn-primary"
                disabled={isFinding}
                onClick={onSubmit}>
                {isFinding ? 'Finding...' : 'Find Jobs'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

JobSearchbar.propTypes = {
  search: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isFinding: PropTypes.bool.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  stateId: PropTypes.string.isRequired,
  loadOptions: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  toggleRemoteJobs: PropTypes.func.isRequired,
  onChangeIndustry: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  onClearKeywords: PropTypes.func.isRequired
};

export default JobSearchbar;
