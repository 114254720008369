import React from 'react';

import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';

import Button from '../../common/Button';
import TextArea from '../../common/TextArea';
import TextInput from '../../common/TextInput';
import VanityTextInput from './VanityTextInput';

import { commonConstant } from '../../../constants/profileConstant';

const BasicInfoForm = (props) => {
  return (
    <div className="content experience add-portfolio-files">
      {props.fieldsToShow === 'headline' && (
        <Row>
          <Col lg="12">
            <TextArea
              name="headline"
              note="65 characters max."
              label={commonConstant.HEADLINE}
              placeholder={commonConstant.ENTER_YOUR_HEADLINE}
              error={props.errors.headline}
              value={props.info.headline}
              onChange={props.onChange}
              rows="8"/>
          </Col>
        </Row>
      )}

      {props.fieldsToShow === 'location' && (
        <Row>
          <Col sm="4">
            <div className="form-group">
              <label className="form-control-label">Country:</label>
              <AsyncPaginate
                cacheOptions
                isSearchable
                name="country"
                additional={{ page: 1, name: 'country' }}
                isClearable={false}
                loadOptions={props.loadOptions}
                placeholder="Country"
                hideSelectedOptions={false}
                value={props.info.country}
                onChange={props.onChangeLocation} />
            </div>
          </Col>
          <Col sm="4">
            <div className="form-group">
              <label className="form-control-label">State:</label>
              <AsyncPaginate
                cacheOptions
                isSearchable
                name="state"
                additional={{ page: 1, name: 'state' }}
                isClearable={false}
                loadOptions={props.loadOptions}
                placeholder="State"
                hideSelectedOptions={false}
                value={props.info.state}
                onChange={props.onChangeLocation} />
            </div>
          </Col>
          <Col sm="4">
            <div className="form-group">
              <label className="form-control-label">City:</label>
              <AsyncPaginate
                cacheOptions
                isSearchable
                name="city"
                additional={{ page: 1, name: 'city' }}
                isClearable={false}
                loadOptions={props.loadOptions}
                placeholder="City"
                hideSelectedOptions={false}
                value={props.info.city}
                onChange={props.onChangeLocation} />
            </div>
          </Col>
        </Row>
      )}

      {props.fieldsToShow === undefined && (
        <div>
          <Row>
            <Col sm="6">
              <TextInput
                name="firstname"
                label={commonConstant.FIRST_NAME}
                placeholder={commonConstant.ENTER_FIRST_NAME}
                error={props.errors.firstname}
                value={props.info.firstname}
                onChange={props.onChange}/>
            </Col>
            <Col sm="6">
              <TextInput
                name="lastname"
                label={commonConstant.LAST_NAME}
                placeholder={commonConstant.ENTER_LAST_NAME}
                error={props.errors.lastname}
                value={props.info.lastname}
                onChange={props.onChange}/>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                name="headline"
                note="65 characters max."
                label={commonConstant.HEADLINE}
                placeholder={commonConstant.ENTER_YOUR_HEADLINE}
                error={props.errors.headline}
                value={props.info.headline}
                onChange={props.onChange}
                rows="8"/>
            </Col>
          </Row>

          <Row>
            <Col sm="4">
              <div className="form-group">
                <label className="form-control-label">Country:</label>
                <AsyncPaginate
                  cacheOptions
                  isSearchable
                  name="country"
                  additional={{ page: 1, name: 'country' }}
                  isClearable={false}
                  loadOptions={props.loadOptions}
                  placeholder="Country"
                  hideSelectedOptions={false}
                  value={props.info.country}
                  onChange={props.onChangeLocation} />
              </div>
            </Col>
            <Col sm="4">
              <div className="form-group">
                <label className="form-control-label">State:</label>
                <AsyncPaginate
                  cacheOptions
                  isSearchable
                  name="state"
                  additional={{ page: 1, name: 'state' }}
                  isClearable={false}
                  loadOptions={props.loadOptions}
                  placeholder="State"
                  hideSelectedOptions={false}
                  value={props.info.state}
                  onChange={props.onChangeLocation} />
              </div>
            </Col>
            <Col sm="4">
              <div className="form-group">
                <label className="form-control-label">City:</label>
                <AsyncPaginate
                  cacheOptions
                  isSearchable
                  name="city"
                  additional={{ page: 1, name: 'city' }}
                  isClearable={false}
                  loadOptions={props.loadOptions}
                  placeholder="City"
                  hideSelectedOptions={false}
                  value={props.info.city}
                  onChange={props.onChangeLocation} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <VanityTextInput
                name="vanity"
                label={commonConstant.CAREER_SITE_URL}
                placeholder={commonConstant.ENTER_VANITY}
                error={props.errors.vanity}
                value={props.info.vanity}
                onChange={props.onChange}/>
            </Col>
          </Row>
        </div>
      )}

      <Row>
        <Col lg="12">
          <div
            style={{marginTop: 15}}
            className="action-bar float-right">
            <Button
              type="button"
              onClick={props.onCancel}
              disabled={props.isUpdating}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            <Button
              type="submit"
              onClick={props.onSave}
              disabled={props.isUpdating}
              buttonClass="btn-primary"
              buttonText={props.isUpdating ? 'Saving...':'Save'}/>
          </div>
        </Col>
      </Row>
    </div>
  );
};

BasicInfoForm.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  fieldsToShow: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  onChangeLocation: PropTypes.func.isRequired
};

export default BasicInfoForm;
