import React from 'react';

import { Link } from 'react-router-dom';

const JobMatchEmptyState = () => {
  return (
    <div className="jobmatch-empty-state">
      <h5>You Haven't Activated Job Match Yet :(</h5>
      <p className="center">You can activate Job Match when you achieve "Advanced" CareerPrepped Status. Job Match uses the data from your Career Site, Career Portfolio and Skill Badges to match you to jobs that fit your skills and interests. The more skill evidence you add and the higher feedback ratings and endorsements you get on your claimed skills, the better service you get from Job Match to help you land your dream job. Even if you’re not currently looking for a job, activating Job Match and reviewing your job matches will help you in your career development journey.</p>
      <Link className="mt-3 underline" to="/home">
        Improve My CareerPrepped Status to activate Job Match
      </Link>
    </div>
  );
};

export default JobMatchEmptyState;
