import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import CpHtmlParser from '../common/CpHtmlParser';

import moment from 'moment';
import classNames from 'classnames';

const industryOptions = [
  {
    value: 24,
    label: 'Accountancy'
  },
  {
    value: 6,
    label: 'Administration'
  },
  {
    value: 45,
    label: 'Advertising'
  },
  {
    value: 26,
    label: 'Animal Care'
  },
  {
    value: 37,
    label: 'Arts and Entertainment'
  },
  {
    value: 28,
    label: 'Banking'
  },
  {
    value: 47,
    label: 'Business & Consumer Services'
  },
  {
    value: 25,
    label: 'Catering'
  },
  {
    value: 31,
    label: 'Cleaning'
  },
  {
    value: 35,
    label: 'Consulting'
  },
  {
    value: 7,
    label: 'Construction'
  },
  {
    value: 50,
    label: 'Construction & Skilled Trades'
  },
  {
    value: 39,
    label: 'Customer Service'
  },
  {
    value: 11,
    label: 'Design'
  },
  {
    value: 12,
    label: 'Education'
  },
  {
    value: 34,
    label: 'Engineering'
  },
  {
    value: 36,
    label: 'Farming and Agriculture'
  },
  {
    value: 27,
    label: 'Finance'
  },
  {
    value: 80,
    label: 'Gig'
  },
  {
    value: 20,
    label: 'Health'
  },
  {
    value: 53,
    label: 'Healthcare'
  },
  {
    value: 48,
    label: 'Hospitality'
  },
  {
    value: 8,
    label: 'Human Resources'
  },
  {
    value: 23,
    label: 'IT'
  },
  {
    value: 40,
    label: 'Insurance'
  },
  {
    value: 21,
    label: 'Legal'
  },
  {
    value: 29,
    label: 'Logistics'
  },
  {
    value: 17,
    label: 'Management'
  },
  {
    value: 42,
    label: 'Manufacturing'
  },
  {
    value: 14,
    label: 'Marketing'
  },
  {
    value: 51,
    label: 'Marketing & Advertising'
  },
  {
    value: 15,
    label: 'Media'
  },
  {
    value: 38,
    label: 'Military'
  },
  {
    value: 46,
    label: 'No Discipline'
  },
  {
    value: 0,
    label: 'Other Professions'
  },
  {
    value: 41,
    label: 'PR'
  },
  {
    value: 19,
    label: 'Property'
  },
  {
    value: 44,
    label: 'Policing'
  },
  {
    value: 33,
    label: 'Public Sector'
  },
  {
    value: 10,
    label: 'Retail'
  },
  {
    value: 16,
    label: 'Sales'
  },
  {
    value: 18,
    label: 'Science'
  },
  {
    value: 52,
    label: 'Science & Engineering'
  },
  {
    value: 13,
    label: 'Security'
  },
  {
    value: 22,
    label: 'Skilled Trades'
  },
  {
    value: 32,
    label: 'Social Care'
  },
  {
    value: 43,
    label: 'Sport and Fitness'
  },
  {
    value: 30,
    label: 'Third Sector'
  },
  {
    value: 49,
    label: 'Transportation'
  },
  {
    value: 9,
    label: 'Travel'
  }
];

const Results = ({ data, index, addToBoard, applyToJob, isSubmitting }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggleTooltip() {
    setTooltipOpen(!tooltipOpen);
  }

  const {
    employer,
    title,
    body,
    url,
    job_id,
    location,
    job_type,
    posted_at,
    discipline_id,
    isSavedProperty
  } = data;
  const dateConfig = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[a week ago]',
    sameElse: 'MM/DD/YYYY'
  };
  const seeMoreCondition = body.replace(/<[^>]+>/g, '').length > 205;
  const descriptionString = body.replace(/<[^>]+>/g, '');
  const description = seeMoreCondition ?
    `${descriptionString.slice(0, 205)}...` : descriptionString.length > 0 ?
      descriptionString :
      'To see the full description of this job, you must visit the site it is published on';

  const displayDate = moment(posted_at).calendar(dateConfig);
  const displayDateClassNames = classNames({
    'badge badge-pill': displayDate === 'Today',
    'card-text': displayDate !== 'Today'
  });
  const titleString = title.replace(/<[^>]+>/g, '');
  const isTitleMoreThanLimit = titleString.length > 30;
  const jobtitle = isTitleMoreThanLimit ?
    `${titleString.slice(0, 30)}...` : titleString;

  const industry = industryOptions.find(industry => industry.value === discipline_id);

  const tooltipText = isSubmitting.status ? 'Saving...' : isSavedProperty ? 'Saved Job' : 'Save Job';

  return (
    <div className="jobsearch-card-container">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title-container">
            <div className="card-title">
              <a href={url} target="_blank">{jobtitle}</a>
            </div>
            <div className="card-title-meta">
              <span className={displayDateClassNames}>{displayDate}</span>
              <span
                id={`Tooltip-${index}`}
                className="clickable-link btn btn-light"
                onClick={isSavedProperty ? undefined : addToBoard(job_id)}>
                <i className={classNames('fa-bookmark', {
                  'job-saved': isSavedProperty,
                  'fas': isSavedProperty,
                  'far': !isSavedProperty
                })}/>
                {/* Tool Tip for Job Save Status */}
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={`Tooltip-${index}`}
                  toggle={toggleTooltip}>
                  {tooltipText}
                </Tooltip>
                {/* <i className="far fa-bookmark"/> */}
              </span>
              <a href="#"
                onClick={applyToJob(job_id, url)}
                className="clickable-link btn orange-btn">
                Apply <i className="fas fa-external-link-alt"/>
              </a>
            </div>

          </h5>
          <div className="jobsearch-card-location-type">
            <p className="card-text mb-0">
              <b>{employer == '' ?? 'Not Provided'}{employer}</b>
              {(location.city || location.state) ? ' - ' : ''}
              {location.city ?? ''}{location.state ? `, ${location.state}` : ''}
            </p>
            <div className="jobsearch-type">
              <p className="card-text mb-0">
                <b>Job Type: </b>{job_type ?? 'Not Provided'}
              </p>
              <p className="card-text mb-0">

                <b>Industry: </b>
                {industry.label == 'No Discipline' ?? 'Not Provided'
                }{industry.label}
              </p>
            </div>
          </div>
          <div className="jobsearch-card-description">
            <CpHtmlParser htmlString={description} />
            <a
              target="_blank"
              href={url}
              className="show-more-btn-job">
              See More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {
  data: PropTypes.object.isRequired,
  addToBoard: PropTypes.func.isRequired,
  applyToJob: PropTypes.func.isRequired,
  isSubmitting: PropTypes.object,
  index: PropTypes.number.isRequired
};

export default Results;
