import React from 'react';

import { Link } from 'react-router-dom';

const JobsSection = () => {
  return (
    <div className="dashboard-quick-links-container mb-3">
      <div className="dashboard-quick-links-header">
        Jobs
      </div>
      <div className="dashboard-quick-links-menu">
        <ul>
          <li>
            <h4>
              <Link to="/job-center">Job Match</Link>
            </h4>
            <p>Get job matches.</p>
          </li>
          <li>
            <h4>
              <Link to="/job-center/job-tracker">Job Tracker</Link>
            </h4>
            <p>Track progress on saved jobs.</p>
          </li>
          <li>
            <h4>
              <Link to="/job-center/resume-builder">
                Resume Builder
              </Link>
            </h4>
            <p>Build targeted resumes on-the-fly.</p>
          </li>
          <li>
            <h4>
              <Link to="/job-center/interview-videos">
                Interview Videos
              </Link>
            </h4>
            <p>Learn to ace job interviews.</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default JobsSection;
