import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getStates } from '../../redux/actions/states';
import { getCities } from '../../redux/actions/cities';
import { closeModal } from '../../redux/actions/components';
import {
  updateSettings
} from '../../redux/actions/job-match-settings';

import {
  selectFormattedStates,
  selectStatesPageCount
} from '../../redux/selectors/states';
import {
  selectFormattedCities,
  selectCitiesPageCount
} from '../../redux/selectors/cities';

import {
  selectJobMatchSettingsObject,
  selectIsUpdatingJobMatchSettings
} from '../../redux/selectors/job-match-settings';

import classNames from 'classnames';
import { omit, isNull, isEqual } from 'lodash';
import Validator from '../../validator';

import Button from '../../components/common/Button';
import TextInput from '../../components/common/TextInput';
import JobTypeSelectOption from '../../components/jobs/JobTypeSelectOption';

import PropTypes from 'prop-types';
import Switch from 'react-switch';
import Select from 'react-select';
import { toastr } from 'react-redux-toastr';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import jobsConstants from '../../constants/jobs';

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      color: '#4b4f56',
      backgroundColor: isFocused ? '#B2D4FF' : 'transparent'
    };
  }
};

const distanceOptions = [
  { value: '5', label: 'within 5 miles' },
  { value: '10', label: 'within 10 miles' },
  { value: '15', label: 'within 15 miles' },
  { value: '25', label: 'within 25 miles' },
  { value: '50', label: 'within 50 miles' }
];

class JobMatchSettingsModal extends Component {
  static propTypes = {
    states: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    modal: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    settingsData: PropTypes.object.isRequired,
    statesPageCount: PropTypes.number.isRequired,
    citiesPageCount: PropTypes.number.isRequired,
    jobMatchSettings: PropTypes.object.isRequired,
    isUpdatingSettings: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      stateId: '',
      settings: { ...this.props.settingsData }
    };

    this.onChange = this.onChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.onChangeDropdown = this.onChangeDropdown.bind(this);
    this.onSaveSettings = this.onSaveSettings.bind(this);
    this.toggleRemoteJobs = this.toggleRemoteJobs.bind(this);
  }

  closeModal() {
    this.props.actions.closeModal();
  }

  async loadOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;
    const { actions, profile } = this.props;

    if (inputName === 'state') {
      await actions.getStates(profile.country.value, page, search);
    }

    if (inputName === 'city') {
      await actions.getCities(this.state.settings.state.value, page, search);
    }

    const options = (inputName === 'state') ? this.props.states : this.props.cities;

    const pageCount = (inputName === 'state') ?
      this.props.statesPageCount : this.props.citiesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  toggleSwitch() {
    this.setState({
      settings: { ...this.state.settings, status: !this.state.settings.status }
    });
  }

  isValid(field = null) {
    let rules = {
      status: ['required'],
      city: ['required'],
      state: ['required'],
      distance: ['required']
    };

    const { errors } = this.state;
    const validate = Validator.createValidator(rules, this.state.settings, field);
    const { errors: newErrors, isValid } = validate;

    if (field && Object.keys(newErrors).length === 0) {
      delete errors[field];
    }

    this.setState({ errors: { ...errors, ...newErrors } });

    return isValid;
  }

  toggleRemoteJobs() {
    this.setState({
      settings: { ...this.state.settings, remoteJobs: !this.state.settings.remoteJobs }
    });
  }

  onChangeDropdown(value, actionMeta) {
    const { name } = actionMeta;

    this.setState({
      settings: {
        ...this.state.settings,
        [name]: value,
        ...((name === 'state') && { city: null })
      },
      ...((name === 'state') && { stateId: value.id })
    },
    () => this.isValid(name));
  }

  onChange(event) {
    const { name, value } = event.target;

    this.setState(
      { settings: { ...this.state.settings, [name]: value } },
      () => this.isValid(name)
    );
  }

  async onSaveSettings() {
    const { settings } = this.state;
    const { actions, jobMatchSettings } = this.props;

    if (isNull(settings.distance)) {
      toastr.error('', 'Distance from location is required.');
      return;
    }

    if (settings.jobInterests === 'Job Goal' && settings.jobGoal === '') {
      this.setState({ errors: { ...this.state.errors, jobGoal: 'Required' } });
      toastr.error('', 'Job goal is required.');
      return;
    }

    if (isNull(settings.jobTypes)) {
      toastr.error('', 'You need to choose your Job Type preferences before saving.');
      return;
    }

    const data = {
      status: Number(settings.status),
      city: settings.city.value,
      distance: settings.distance.value,
      jobInterests: settings.jobInterests,
      jobGoal: settings.jobGoal,
      jobTypes: settings.jobTypes,
      remoteJobs: Number(settings.remoteJobs)
    };

    await actions.updateSettings(jobMatchSettings.id, data);

    this.closeModal();
  }

  render() {
    const { stateId, errors, settings } = this.state;
    const { modal, settingsData, isUpdatingSettings } = this.props;
    const isJobMatchSettingsModalOpen = modal === 'job-match-settings-modal';

    const formDataIsEqual = isEqual(settings, settingsData);

    return (
      <Modal
        size="lg"
        className="modal-margin-top"
        isOpen={isJobMatchSettingsModalOpen}
        backdrop="static"
        toggle={this.closeModal}>
        <ModalHeader toggle={this.closeModal}>
          Job Match Settings
        </ModalHeader>
        <ModalBody>
          <p className="font14">Your Job Match settings allow you to turn Job Match on or off and to adjust some of the data we use to find job matches for you. Adjust these settings at any time through the settings menu.</p>
          <Row className="mb-2">
            <Col lg="12">
              <label className="form-control-label mb-1">
                Job Match Status
              </label>
            </Col>
            <Col lg="12">
              <div className="form-group mb-2 mt-1">
                <Switch
                  handleDiameter={24}
                  offColor="#CCC"
                  onColor="#008800"
                  checked={!!settings.status}
                  onChange={this.toggleSwitch}
                  height={32}
                  width={70}
                  borderRadius={6}
                  uncheckedIcon={
                    <div style= {{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      color: 'gray',
                      fontSize: 14,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingRight: 8,
                      paddingLeft: 8
                    }}>
                      Off
                    </div>
                  }
                  checkedIcon={
                    <div style= {{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      color: 'white',
                      fontSize: 14,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingRight: 8,
                      paddingLeft: 8
                    }}>
                      On
                    </div>
                  }/>
              </div>
            </Col>
            <Col lg="12">
              <label>
                <div>
                  <span className={classNames('font14 form-control-label', {
                    'text-danger': !settings.status,
                    'text-success': settings.status
                  })}>
                    {settings.status ? 'ON' : 'OFF'}
                  </span>:&nbsp;
                  {settings.status ?
                    'You will receive job opportunities from across the web.' :
                    'You won\'t receive job opportunities from across the web.'}
                </div>
              </label>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="12">
              <label className="form-control-label mb-1">
                Location
              </label>
              <small> (We will find jobs in this location)</small>
            </Col>
            <Col lg="4" className="pr-lg-1">
              <div className="form-group">
                <label className="form-control-label mb-1 font12">State</label>
                <AsyncPaginate
                  cacheOptions
                  isSearchable
                  name="state"
                  additional={{ page: 1, name: 'state' }}
                  isClearable={false}
                  loadOptions={this.loadOptions}
                  placeholder="State"
                  hideSelectedOptions={false}
                  value={settings.state}
                  onChange={this.onChangeDropdown} />
              </div>
            </Col>
            <Col lg="4" className="pr-lg-1 pl-lg-1">
              <div className="form-group">
                <label className="form-control-label mb-1 font12">City</label>
                <AsyncPaginate
                  cacheOptions
                  isSearchable
                  name="city"
                  cacheUniqs={[stateId]}
                  additional={{ page: 1, name: 'city' }}
                  isClearable={false}
                  loadOptions={this.loadOptions}
                  placeholder="City"
                  hideSelectedOptions={false}
                  value={settings.city}
                  onChange={this.onChangeDropdown} />
              </div>
            </Col>
            <Col lg="4" className="pl-lg-1">
              <div className="form-group">
                <label className="form-control-label mb-1 font12">Distance from location</label>
                <Select
                  name="distance"
                  options={distanceOptions}
                  value={settings.distance}
                  onChange={this.onChangeDropdown}
                  placeholder="Select Distance"/>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  onChange={this.toggleRemoteJobs}
                  checked={settings.remoteJobs}
                  className="form-check-input"
                  id="remote-jobs" />
                <label
                  className="form-check-label"
                  htmlFor="remote-jobs">
                  Include Remote Jobs
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <label className="form-control-label mb-1">
                Job Goal
              </label><br/>
              <label>Find jobs based on my specific job goal</label>
              <TextInput
                name="jobGoal"
                error={errors.jobGoal}
                onChange={this.onChange}
                value={settings.jobGoal}
                disabled={settings.jobInterests !== 'Job Goal'}
                placeholder="Enter one specific job goal such as Graphic Designer"/>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <label className="form-check-label">
                Job Type Preferences
              </label>
              <Select
                isMulti
                name="jobTypes"
                placeholder="Select Job Type"
                isSearchable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={colourStyles}
                className="multiselect-container multiSelectContainer"
                components={{Option: JobTypeSelectOption}}
                options={jobsConstants.jobTypeOptions}
                value={settings.jobTypes}
                onChange={this.onChangeDropdown}/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="clearfix">
          <Button
            onClick={this.onSaveSettings}
            buttonClass="btn btn-primary float-right"
            buttonText={
              `${isUpdatingSettings ?
                'Saving Settings...' : 'Save Settings'}`
            }
            disabled={formDataIsEqual || isUpdatingSettings}/>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const jobMatchSettings = selectJobMatchSettingsObject(state);
  const settings = omit(jobMatchSettings, ['id', 'createdAt', 'updatedAt', '_links']);

  const settingsData = {
    ...settings,
    jobInterests: 'Job Goal',
    distance: jobMatchSettings.distance === '' ?
      null : distanceOptions.find(option => option.value === jobMatchSettings.distance)
  };

  return {
    settingsData,
    jobMatchSettings,
    profile: state.profile.data,
    modal: state.components.modal,
    states: selectFormattedStates(state),
    statesPageCount: selectStatesPageCount(state),
    cities: selectFormattedCities(state),
    citiesPageCount: selectCitiesPageCount(state),
    isUpdatingSettings: selectIsUpdatingJobMatchSettings(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getStates,
    getCities,
    closeModal,
    updateSettings
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobMatchSettingsModal);
