import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModal } from '../../redux/actions/components';

import PropTypes from 'prop-types';

import Button from '../common/Button';
import JobMatchEmptyStateImg from '../../assets/images/empty-state-job-match.svg';

const JobMatchEmptyStateAdvanced = ({ actions }) => {
  function openModal() {
    actions.openModal('job-match-settings-modal');
  }

  return (
    <div className="jobmatch-empty-state">
      <div className="d-flex flex-column flex-md-row">
        <img className="mr-4 mb-3" style={{ maxWidth: 260 }} src={JobMatchEmptyStateImg} />
        <div className="text-left mt-3">
          <h5>You’re ready to activate Job Match!</h5>
          <p className="center">Congrats on getting Advanced Status. You can now turn on Job Match.</p>
          <Button
            type="button"
            buttonClass="btn-primary ml-0"
            onClick={openModal}
            buttonText="Turn on Job Match"/>
        </div>
      </div>
    </div>
  );
};

JobMatchEmptyStateAdvanced.propTypes = {
  actions: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openModal }, dispatch)
});

export default connect(null, mapDispatchToProps)(JobMatchEmptyStateAdvanced);
