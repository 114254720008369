import React from 'react';
import { pure } from 'recompose';

import { useParams } from 'react-router-dom';

import { isUndefined } from 'lodash';

import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import WallComponent from './WallComponent';
import InfiniteScroller from '../../containers/common/infinite-scroll/InfiniteScroller';

const WallContainer = ({
  callAPI,
  wallPosts,
  wallShare,
  shouldCallAPIInitially,
  onChangeActivityFilter
}) => {
  const { postId } = useParams();
  const { paginationData } = wallPosts;

  return (
    <div className="container dashboard-activity">
      <div className="row">
        <div className="col-lg-3">
          <div className="dashboard-activity-filter">
            <label htmlFor="activity-filter" className="sr-only">Activity Filter</label>
            <Input
              type="select"
              name="activity-filter"
              id="activity-filter"
              className="form-control form-control-md dashboard-activity-filter-inputs"
              onChange={onChangeActivityFilter}>
              <option value="all_activity">All Activity</option>
              <option value="my_activity">My Activity</option>
              <option value="following_activity">Following Activity</option>
            </Input>
          </div>
        </div>
        <div className="col-lg-9">
          {(!isUndefined(postId)) &&
            <WallComponent
              wallPosts={wallPosts}
              wallShare={wallShare}/>}

          {isUndefined(postId) &&
            <InfiniteScroller
              component={WallComponent}
              callAPI={callAPI}
              page_count={paginationData.page_count}
              page={paginationData.page}
              shouldCallAPIInitially={shouldCallAPIInitially}
              wallShare={wallShare}
              wallPosts={wallPosts}
              isLoaderInternal/>}
        </div>
      </div>
    </div>
  );
};

WallContainer.propTypes = {
  wallPosts: PropTypes.object.isRequired,
  wallShare: PropTypes.object.isRequired,
  callAPI: PropTypes.func.isRequired,
  onChangeActivityFilter: PropTypes.func.isRequired,
  shouldCallAPIInitially: PropTypes.bool,
};

export default pure(WallContainer);
