import PropTypes from 'prop-types';
import React from 'react';

import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const ProfileDropdownComponent = ({ ...props }) => {
  const caretStyles = classNames('fa ml-3', {
    'fa-caret-down': !props.collapse,
    'fa-caret-up': props.collapse
  });

  const { avatar, name, vanity, role, adminInstitutions } = props.currentUser;

  const isSuperAdmn = role === 'super-admin';
  const isEducator = role === 'educator';
  const isInstitutionAdmin = role === 'institution-admin';
  const isGroupAdmin = (role === 'user' || role === 'group-admin')
    && adminInstitutions && adminInstitutions.length > 0;

  return (
    <div className="nav-dd" id="nav-dd">
      <a
        className="nav-link profile-dropdown clickable pr-0"
        onClick={props.toggleProfileDropdown}>
        {avatar && avatar !== '' ?
          <img
            className="img-responsive header-image"
            src={avatar}
            alt={`${name}'s Avatar`} /> :
          <span
            style={{
              width: '32px',
              height: '32px',
              marginRight: '10px',
              borderRadius: '16px',
              lineHeight: '32px',
              display: 'inline-block'
            }}
            className="img-responsive initial-avatar">
            {name && name.slice(0, 1)}
          </span>}
        <span>
          {props.isMobile ? 'Me' : name}
        </span>
        <i className={caretStyles} />
      </a>

      <Collapse isOpen={props.collapse} id="profile" className="side-menu">
        <Link
          onClick={props.toggleProfileDropdown}
          to={`/cp/${vanity}`}
          className="profile-item view-webpage-link">
          {
            avatar && avatar !== '' ?
              <img
                className="img-responsive header-image profile-dropdown-avatar"
                src={avatar}
                alt={`${name}'s Avatar`} /> :
              <span
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '10px',
                  borderRadius: '12px',
                  lineHeight: '24px',
                  display: 'inline-block'
                }}
                className="img-responsive profile-dropdown-avatar initial-avatar">
                {name && name.slice(0, 1)}
              </span>
          }
          View Career Site
        </Link>
        {isSuperAdmn &&
          <div>
            <Link
              onClick={props.toggleProfileDropdown}
              to="/admin/skill-builders"
              className="profile-item">
              Authoring Tool
            </Link>
            <Link
              onClick={props.toggleProfileDropdown}
              to="admin/manage-organizations"
              className="profile-item">
              Manage Organizations
            </Link>
            <Link
              onClick={props.toggleProfileDropdown}
              to="admin/manage-access-requests"
              className="profile-item">
              Manage Access Requests
            </Link>
            <Link
              onClick={props.toggleProfileDropdown}
              to="admin/statistics"
              className="profile-item">
              Admin Statistics
            </Link>
          </div>}

        {
          (isEducator) &&
          <Link
            onClick={props.toggleProfileDropdown}
            to="/educator/groups"
            className="profile-item">
            Admin Panel
          </Link>
        }

        {
          (isInstitutionAdmin || isGroupAdmin) &&
          <Link
            onClick={props.toggleProfileDropdown}
            to="/organization/admin"
            className="profile-item">
            Admin Panel
          </Link>
        }

        <Link
          onClick={props.toggleProfileDropdown}
          to="/settings"
          className="profile-item">
          Settings &amp; Privacy
        </Link>

        <a
          target="_blank"
          rel="noopener"
          href="https://help.careerprepped.com/help"
          className="profile-item">
          Help Center
        </a>

        {
          (isInstitutionAdmin || isGroupAdmin) &&
          <a
            target="_blank"
            rel="noopener"
            href="https://institutions.careerprepped.com/help"
            className="profile-item">
            Institutional Support
          </a>
        }

        <a
          className="profile-item logout-dropdown-item"
          onClick={props.onLogout}>
          Logout
        </a>
      </Collapse>
    </div>
  );
};

ProfileDropdownComponent.propTypes = {
  collapse: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  toggleProfileDropdown: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};


export default ProfileDropdownComponent;
