import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

const notFoundIcon = require('../../assets/images/404.png');

class NotFound extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  render(){
    const { isAuthenticated } = this.props;

    return (
      <div id="section1" className={classNames('section', { info: !isAuthenticated })}>
        <div className="container">
          <div className="flex-center text-center mt-3">
            <img className="img-fluid mt-3" src={notFoundIcon} width="500" alt="404 - Not Found"/>
            <h2 className="mt-2 mb-2">Whoops...Looks like you're lost!</h2>
            <Link className="btn btn-lg btn-primary" to={isAuthenticated ? '/home' :'/'}>Let's take you back home!</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });

export default connect(mapStatetoProps)(NotFound);
