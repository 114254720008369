import React, { Component } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import {
  selectJobMatchSettingsObject,
  selectIsRequestingJobMatchSettings
} from '../../redux/selectors/job-match-settings';

import JobMatchesList from './JobMatchesList';
import JobMatchEmptyStateAdvanced from '../../components/jobs/JobMatchEmptyStateAdvanced';

class JobMatches extends Component {
  static propTypes = {
    jobMatchSettings: PropTypes.object.isRequired,
    isRequestingJobMatchSettings: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { jobMatchSettings, isRequestingJobMatchSettings } = this.props;
    const { status: jobMatchSettingsStatus } = jobMatchSettings;

    return (
      <div>
        {(jobMatchSettingsStatus && (!isRequestingJobMatchSettings)) ?
          <JobMatchesList /> :
          <JobMatchEmptyStateAdvanced />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  jobMatchSettings: selectJobMatchSettingsObject(state),
  isRequestingJobMatchSettings: selectIsRequestingJobMatchSettings(state)
});

export default connect(mapStateToProps)(JobMatches);
