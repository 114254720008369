import React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

const IndustryMentorsModal = ({ showIndustryMentorsModal, toggleIndustryMentorsModal }) => {
  return (
    <Modal isOpen={showIndustryMentorsModal} toggle={toggleIndustryMentorsModal} size={'md'}>
      <div className="skillbuilder sbmodals" id="skillBuilder">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel" style={{fontSize:'1.5rem'}}>Industry Mentors</h4>
            <button type="button" className="close" onClick={toggleIndustryMentorsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="mt-3 text-center">
              Industry mentors are working professionals who've joined the CareerPrepped community to evaluate, rate, and offer constructive feedback on how well they feel your evidence proves your claimed skills.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

IndustryMentorsModal.propTypes = {
  showIndustryMentorsModal: PropTypes.bool.isRequired,
  toggleIndustryMentorsModal: PropTypes.func.isRequired
};

export default IndustryMentorsModal;
