import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';
import { pure } from 'recompose';

const sortDataArray = [
  {
    id: 1,
    name: 'newest',
    display: 'Newest'
  },
  {
    id: 2,
    name: 'likes',
    display: 'Most Likes'
  },
  {
    id: 3,
    name: 'comments',
    display: 'Most Comments'
  },
  {
    id: 4,
    name: 'file',
    display: 'Posts with Files'
  }
];

const ActivityFilter = ({
  dashboard,
  activeSort,
  isSortByOpen,
  toggleSortBy,
  handleBeforeSorting
}) => {
  const wrapperClass = classNames({
    'container': dashboard
  });

  const activeSortObject = sortDataArray.find((data) => {
    if(activeSort === 'date'){
      return sortDataArray[0];
    }

    return activeSort === data.name;
  });

  return (
    <div className={wrapperClass} style={{ marginBottom: 15 }}>
      <div className="row">
        <div className="col-lg-9 offset-lg-3">
          <div className="activity-filter-sort">
            <div className="btn-group">
              <Dropdown
                isOpen={isSortByOpen}
                toggle={toggleSortBy}>
                <DropdownToggle className="btn-dropdown-toggle">
                  {activeSortObject.display}
                </DropdownToggle>

                <DropdownMenu>
                  {
                    sortDataArray.map((item) => {
                      if(item.id === 1){
                        return (
                          <a
                            key={item.id}
                            className={classNames(
                              'dropdown-item',{
                                'active-item-filter': ['newest','date'].includes(activeSort)
                              }
                            )}
                            id={dashboard ? 'newest':'date'}
                            onClick={handleBeforeSorting}>
                            {item.display}
                          </a>);
                      }

                      return (
                        <a
                          key={item.id}
                          className={classNames(
                            'dropdown-item',{
                              'active-item-filter': activeSort === item.name
                            }
                          )}
                          id={item.name}
                          onClick={handleBeforeSorting}>
                          {item.display}
                        </a>
                      );

                    }
                    )
                  }
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ActivityFilter.propTypes = {
  isSortByOpen: PropTypes.bool.isRequired,
  toggleSortBy: PropTypes.func.isRequired,
  dashboard: PropTypes.bool,
  activeSort: PropTypes.string,
  handleBeforeSorting: PropTypes.func.isRequired
};

export default pure(ActivityFilter);
