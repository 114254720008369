import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TestimonialSlider from '../TestimonialSlider';

const header_image = require('../../../assets/images/homepage/organization/img-organization-header.png');
const img_skills_based = require('../../../assets/images/homepage/organization/img-mentors.png');
const img_skills_to_careers = require('../../../assets/images/homepage/organization/img-skills-to-careers.jpg');
const img_skillbuilders_menu = require('../../../assets/images/homepage/organization/img-skillbuilders_menu.png');

const TalentDevelopers = ({ toggleRequestInvite }) => {
  
  return (
    <div className="talent-developers">
      <div className="td-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <header>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="top-line">CAREER ED & WORKFORCE DEVELOPMENT ORGANIZATIONS</div>
                    <h2>Make <span>every skill</span> of every learner <span>count for career success</span></h2>
                  </div>
                  <div className="col-lg-6">
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa-solid fa-check" /></span>Track learners' skill attainment, not just course completion and grades.</li>
                      <li><span className="fa-li"><i className="fa-solid fa-check" /></span>Empower learners to demonstrate their applied skills with authentic artifacts.</li>
                      <li><span className="fa-li"><i className="fa-solid fa-check" /></span>Engage employers in giving learners feedback and connect them with potential hires.</li>
                      <li><span className="fa-li"><i className="fa-solid fa-check" /></span>Identify skill perception gaps among different stakeholders and work towards closing them.</li>
                      <li><span className="fa-li"><i className="fa-solid fa-check"/></span>Leverage AI to auto-match learners to jobs that fit their skills. </li>
                    </ul>
                    <div className="action-button-cont">
                      <button
                        className="btn signup-btn"
                        onClick={toggleRequestInvite}>
                        Get in Touch
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <img src={header_image} className="img-fluid"/>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
      <article id="skills-based">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>A unique, skills-based <b>360° feedback</b> system makes learners' skills visible and verifiable</h2>
              <p>CareerPrepped lets learners showcase real-world evidence of their skills and then seek feedback from the community to understand which of their skills are perceived to be well proven and which need stronger evidence. Learners can get feedback from all members of the community, including educators and employers.  </p>
              <p>CareerPrepped's feedback system enables learners to continuously improve their skill evidence and feedback ratings to better signal their proven skills to employers. The platform also allows learners to get authentic endorsements and testimonials from people who’ve seen their skills in action. </p>
            </div>
            <div className="col-lg-6">
              <img src={img_skills_based} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </article>
      <article id="competency-based">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>An innovative, <b>competency-based</b> system helps learners develop and demonstrate their soft skills</h2>
              <p>Essential workforce skills, often referred to as soft skills, are the set of skills needed to succeed in any industry and empower people to achieve lifelong career success. CareerPrepped offers a wide range of skill builders and associated tools to help learners continuously build, prove, and showcase their soft skills.</p>
              <p>CareerPrepped's innovative approach to soft skills development and demonstration has been formally acknowledged by the Accrediting Commission of Career Schools and Colleges (ACCSC) and the platform has been adopted to support the agency's <a href="https://www.essentialworkforceskills.org/" target="_blank">Essential Workforce Skills Programmatic Certification</a>.</p>
            </div>
            <div className="col-lg-6">
              <img src={img_skillbuilders_menu} className="img-fluid" alt="Skill Builders" />
            </div>
          </div>
        </div>
      </article>
      <article id="employer-driven">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>An employer-driven, <b>machine intelligence</b> system matches learners to jobs that fit their skills</h2>
              <p>CareerPrepped provides a comprehensive suite of tools to assist learners in translating their skills to career opportunities. Learners can showcase their experiences, skills, and achievements through their own personal career site backed by multimedia evidence of their skills stored in their career portfolio.</p>
              <p>The platform's AI-based job match tool analyzes job listings across the internet and delivers personalized job matches based on learners' skills and preferences. Additionally, the platform offers a job tracker for learners to track their job applications, a resume builder for generating personalized resumes, and a library of interview prep videos to enhance learners' interviewing skills.</p>
            </div>
            <div className="col-lg-6">
              <div className="verifiable-skills">
                <img src={img_skills_to_careers} className="img-fluid" alt="Verifyable Skills" />
                <p>Verifiable skills are becoming the currency of the labor market. Connect your learners to the world of work through the same AI technologies that are deployed in leading Applicant Tracking Systems and Human Resources Information Systems, used by top staffing agencies and corporations worldwide.</p>
              </div>
            </div>
          </div>
        </div>
      </article>
     
      <article className="finally">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1>Give your learners the tools they need to achieve career success in a skills-based economy</h1>
              <p style={{marginRight: '50px'}}>
                Use our self-help <Link to="/toolkit">Toolkit</Link> to enrich your existing services. Start by creating groups to organize your learners and invite them to join. Encourage your learners to prove their skills with evidence and engage your entire community virtually to give them feedback on their skill evidence. Gain a better understanding of your learners' skills and identify skill perception gaps revealed from feedback. Access your learners' data to tailor your support services and let our machine intelligence match them with jobs that fit their skills.
              </p>
            </div>
            <div className="col-lg-5">
              <ul className="list-group">
                <li className="list-group-item header">
                  Talent Developer
                  <span>Subscription Account</span>
                </li>
                <li className="list-group-item">
                  Unlimited Groups
                  <span>Create groups to organize your users</span>
                </li>
                <li className="list-group-item">
                  Unlimited Admins
                  <span>Add group admins to any group</span>
                </li>
                <li className="list-group-item">
                  Usage Report
                  <span>Get stats on each user + cumulative stats</span>
                </li>
                <li className="list-group-item">
                  Implementation Support
                  <span>Get access to resource center + consultation</span>
                </li>
                <li className="list-group-item">
                  Search Tool
                  <span>Get access to user directory search features</span>
                </li>
                <li className="list-group-item">
                  Industry Mentors System
                  <span>Invite industry mentors  to give feedback</span>
                </li>
                <li className="list-group-item contact">
                  <button
                    className="btn signup-btn"
                    onClick={toggleRequestInvite}>
                    Get in touch
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
      <article className="iltestimonials">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <TestimonialSlider />  
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

TalentDevelopers.propTypes = {
  toggleRequestInvite: PropTypes.func.isRequired,
};


export default TalentDevelopers;
