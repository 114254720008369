import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as jobSearchActions from '../../redux/actions/job-search';
import { bindActionCreators } from 'redux';
import InfiniteScroller from '../common/infinite-scroll/InfiniteScroller';
import JobSearchResults from '../../components/jobs/JobSearchResults';

class ResultsWrapper extends Component {
  static propTypes = {
    jobSearch: PropTypes.object,
    search: PropTypes.object.isRequired,
    addToBoard: PropTypes.func.isRequired,
    applyToJob: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    deleteJob: PropTypes.func.isRequired,
    onChangeSort: PropTypes.func.isRequired,
    isDeleting: PropTypes.object.isRequired,
    onConfirmDeleteJob: PropTypes.func.isRequired,
    onCancelDeleteJob: PropTypes.func.isRequired,
    confirmDeleteJobModalIsOpen: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.callAPI = this.callAPI.bind(this);
  }

  callAPI() {
    const { actions, jobSearch } = this.props;
    const { end, page } = jobSearch.data;

    const queryParams = Object.assign(
      {},
      jobSearch.queryParams,
      { startIndex: end, page: page+1 }
    );

    return actions.searchJobs(queryParams);
  }

  render() {
    const {
      search,
      addToBoard,
      applyToJob,
      jobSearch,
      deleteJob,
      isDeleting,
      onChangeSort,
      onConfirmDeleteJob,
      onCancelDeleteJob,
      confirmDeleteJobModalIsOpen
    } = this.props;
    const { page, pages_total } = jobSearch.data;

    return (
      <InfiniteScroller
        search={search}
        onChangeSort={onChangeSort}
        isSubmitting={jobSearch.isSubmitting}
        component={JobSearchResults}
        results={jobSearch.data}
        applyToJob={applyToJob}
        addToBoard={addToBoard}
        page_count={pages_total}
        callAPI={this.callAPI}
        page={page}
        isLoaderInternal
        deleteJob={deleteJob}
        isDeleting={isDeleting}
        confirmDeleteJobModalIsOpen={confirmDeleteJobModalIsOpen}
        onConfirmDeleteJob={onConfirmDeleteJob}
        onCancelDeleteJob={onCancelDeleteJob}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jobSearch: state.jobSearch,
    isDeleting: state.jobBoards.isDeleting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobSearchActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsWrapper);
