import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import { Collapse } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import * as registrationActions from '../../redux/actions/registration';

import IndustryMentorForm from '../../components/industry-mentor/IndustryMentorForm';

import Validator from '../../validator';

const talentdevorg = require('../../assets/images/homepage/img-talent-development.png');

const accordionData = [
  {
    id: 1,
    title: 'Who can be an industry mentor?',
    content: 'We\'re looking for recruiters, hiring managers, supervisors, working or retired professionals who have experience in evaluating skill claims and enjoy offering constructive feedback to help people from diverse backgrounds build, prove and showcase their skills.'
  },
  {
    id: 2,
    title: 'How does it work?',
    content: 'Community members provide multimedia evidence to prove their skill claims and request feedback on how well their evidence proves their claimed skill. Once a request is made, our system automates matching to industry mentors who are notified they have a request to review the evidence and offer constructive feedback.'
  },
  {
    id: 3,
    title: 'How much time does it take?',
    content: 'You\'re in full control of how many requests you want monthly. You can even pause or stop requests any time and you choose if you want to respond to each request or ignore.'
  },
  {
    id: 4,
    title: 'Who are the mentees?',
    content: 'CareerPrepped community members are diverse and include high school students, college students, educators, early career professionals, career changers, working professionals, other industry mentors, and everything in-between. We\'re empowering individuals with diverse backgrounds to have their skills recognized and to work actively in recognizing others\' skills. '
  }
];

const industryMentor = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  linkedInProfile: '',
  alternativeOnlineProfile: '',
  industryRole: '',
  jobTitle: '',
  companyName: '',
  referral: '',
};

class TalentDevOrg extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      openCollapsibleId: 0,
      industryMentor,
      isOpenIndustryMentorModal: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickCollapsible = this.onClickCollapsible.bind(this);
    this.toggleIndustryMentorModal = this.toggleIndustryMentorModal.bind(this);
  }

  isValid(field = null) {
    const { industryMentor, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      'firstName': ['required'],
      'lastName': ['required'],
      'email': ['required', 'email'],
      'phoneNumber': ['phone'],
      'linkedInProfile': ['required', 'url'],
      'alternativeOnlineProfile': ['url'],
      'industryRole': ['required'],
      'jobTitle': ['required'],
      'companyName': ['required'],
      'referral': ['required']
    }, industryMentor, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  toggleIndustryMentorModal() {
    this.setState({ isOpenIndustryMentorModal: ! this.state.isOpenIndustryMentorModal });
  }

  onClickCollapsible(id) {
    return () => this.setState({
      openCollapsibleId: this.state.openCollapsibleId === id ? 0 : id
    });
  }

  onChange(event) {
    const { name, value } = event.target;
    const { industryMentor: industryMentorState } = this.state;

    const industryMentor = { ...industryMentorState, [name]: value };

    this.setState({ industryMentor }, () => this.isValid(name));
  }

  onSubmit(event) {
    event.preventDefault();

    const { industryMentor: industryMentorState } = this.state;

    if (! this.isValid()) return false;

    const { firstName, lastName, companyName, ...mentor } = industryMentorState;
    const name = `${firstName.trim()} ${lastName.trim()}`;

    const data = {
      ...mentor,
      name,
      organization: companyName,
      careerClusters: JSON.stringify(industryMentorState.careerClusters)
    };

    this.props.actions.industryMentorRequest(data)
      .then(() => {
        this.setState({ industryMentor });
        toastr.success('', 'Your industry mentor request was successfully submitted!');
      });
  }

  render() {
    const { isSubmitting } = this.props;
    const {
      openCollapsibleId,
      errors,
      industryMentor,
      isOpenIndustryMentorModal
    } = this.state;

    return (
      <div className="for-org non-logged-homepage">
        <Helmet title="Industry Mentors"/>

        <div className="talent-development-orgs">
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>Skills to Careers Industry Mentors Program</h1>
                </div>
                <div className="col-lg-10 offset-lg-1">
                  <p>
                    Employers are increasingly choosing to hire on the basis of skills instead of exclusively considering education level and work histories. Yet, many skills are hidden, unproven or unrecognized. CareerPrepped is making skill demonstration and recognition more accessible and equitable. By doing so, we empower more people to make their skills visible and verifiable, regardless of how they were attained, enabling skills-based hiring at scale.
                  </p>
                </div>
              </div>
            </div>
          </header>
          <article className="talent-development-orgs_info">
            <div className="container">
              <div className="row">
                <div className="container">
                  <div className="talent-development-orgs_info_content">
                    <div className="row row-eq-height">
                      <div className="col-lg-7">
                        <p>
                          Join CareerPrepped as an Industry Mentor to  support skills-based hiring and help people succeed in their careers. Connect with community members to evaluate, rate, and offer constructive feedback on how well you feel their portfolio evidence proves their claimed skills.
                        </p>
                        <ul className="fa-ul">
                          <li>
                            <i className="fa-li fa fa-check"/>Be a brand ambassador for your organization
                          </li>
                          <li>
                            <i className="fa-li fa fa-check"/>Build your own professional brand
                          </li>
                          <li>
                            <i className="fa-li fa fa-check"/>Become a role model for aspiring professionals
                          </li>
                          <li>
                            <i className="fa-li fa fa-check"/>Connect with potential hires in a meaningful way
                          </li>
                          <li>
                            <i className="fa-li fa fa-check"/>Enhance your feedback skills and leadership qualities
                          </li>
                          <li>
                            <i className="fa-li fa fa-check"/>Get free access to our <Link to="/toolkit">Talent Toolkit</Link> for your own use
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-5">
                        <img src={talentdevorg}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article className="talent-development-orgs_form">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="talent-developer-orgs_form_content">
                    <h2>Industry Mentor Account</h2>
                    <div className="row justify-content-center">
                      <div className="account_form_submit-cont">
                        <button
                          className="btn account-signup-btn"
                          onClick={this.toggleIndustryMentorModal}
                          style={{
                            'textTransform':'none',
                            'display':'inline-block',
                            'whiteSpace':'normal'
                          }}>
                          <strong>
                            Sign Up to Become an Industry Mentor
                          </strong>
                        </button>
                      </div>
                    </div>
                  </div>
                  {isOpenIndustryMentorModal &&
                    <IndustryMentorForm
                      errors={errors}
                      onChange={this.onChange}
                      onSubmit={this.onSubmit}
                      isSubmitting={isSubmitting}
                      industryMentor={industryMentor}
                      isOpenIndustryMentorModal={isOpenIndustryMentorModal}
                      toggleIndustryMentorModal={this.toggleIndustryMentorModal}/>}
                </div>
                <div className="col-lg-5">
                  <div className="accordion">
                    {accordionData.map(({ title, content, id }) => (
                      <div
                        className="card"
                        data-toggle="collapse"
                        data-target={`#collapse${id}`}
                        aria-expanded={openCollapsibleId === id}
                        aria-controls={`#collapse${id}`}
                        onClick={this.onClickCollapsible(id)}
                        key={id}>
                        <div className="card-header">
                          {title}
                        </div>
                        <Collapse isOpen={openCollapsibleId === id} id={`#collapse${id}`}>
                          <div className="card-content">{content}</div>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ registration: { isSubmitting } }) => ({ isSubmitting });

const mapDispatchToProps = dispatch =>
  ({ actions: bindActionCreators(registrationActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(TalentDevOrg);
