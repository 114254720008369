import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import BeginnerIcon from '../../../assets/images/beginner.svg';
import AdvancedIcon from '../../../assets/images/advanced.svg';

const UsersTableStatusCell = (props) => {
  const { user } = props;
  const percentile = (user.userStatus.overallStatus) ? user.userStatus.overallStatus : 0;
  const cpStatusTitleAlias = {
    'skills': 'Add 5 Skills',
    'summary': 'Add a Summary',
    'location': 'Add Location',
    'skillBuilder': 'Claim 3 Skill Badges',
    'skillBuilderEvidence': 'Add Portfolio Evidence',
    'skillEvidence': 'Add Evidence',
    'education': 'Add Education',
    'experience': 'Add Experience',
    'worksituation': 'Save work situation',
    'headline': 'Add Headline',
    'avatar': 'Upload an Avatar',
    'backgroundimage': 'Add Background Image'
  };

  return (
    <div>
      {(percentile > 49 && percentile < 99) ? (
        <span
          className="font14 font-weight-bold"
          style={{ textAlign: 'center' }} >
          <div className="text-center">
            <div className="customLevelImg">
              <img src={require('../../../assets/images/intermediate.png')} id={`statusStage${user.id}`} data-value={user.id} className="pointer" color="defult"/>
            </div>
            <div>Intermediate</div>
          </div>
        </span>
      ) : percentile > 99 ? (
        <span
          className="font14 font-weight-bold"
          style={{ textAlign: 'center' }} >
          <div className="text-center">
            <div className="customLevelImg">
              <img src={AdvancedIcon} id={`statusStage${user.id}`} data-value={user.id} className="pointer" color="defult"/>
            </div>
            <div>Advanced</div>
          </div>
        </span>
      ) : (
        <span
          className="font14 font-weight-bold"
          style={{ textAlign: 'center' }} >
          <div className="text-center">
            <div className="customLevelImg">
              <img src={BeginnerIcon} id={`statusStage${user.id}`} data-value={user.id} className="pointer" color="defult"/>
            </div>
            <div>Beginner</div>
          </div>
          {props.statusClicked[user.id]}
        </span>
      )}

      <UncontrolledPopover
        placement="bottom"
        target={`statusStage${user.id}`}
        className="careerstatus-popover"
        trigger="legacy">
        <PopoverBody>
          {user.userStatus &&
            user.userStatus?.completedCards?.map((action) => {
              return (
                <Row key={action.id}>
                  <Col md="10">
                    <span>{cpStatusTitleAlias[action.statusType]}</span>
                  </Col>
                  <Col md="2" className="text-right">
                    <i className="fa fa-check-circle" />
                  </Col>
                </Row>
              );
            }
            )}

          {user.userStatus &&
            user.userStatus?.pendingCards?.map((action) => {
              return (
                <Row key={action.id}>
                  <Col md="10">
                    <span>{cpStatusTitleAlias[action.statusType]}</span>
                  </Col>
                  <Col md="2" className="text-right">
                    <i
                      style={{ color: 'red' }}
                      className="fa fa-times-circle" />
                  </Col>
                </Row>
              );
            }
            )}
        </PopoverBody>
      </UncontrolledPopover>

    </div>
  );
};

UsersTableStatusCell.propTypes = {
  user: PropTypes.object,
  statusClicked: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default UsersTableStatusCell;
