import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

const selectJobMatches = state => state.jobMatches;

export const selectJobMatchesList = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.data
);

export const selectJobMatchesCities = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.cities
);

export const selectJobMatchesCitiesPaginationData = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.citiesPaginationData
);

export const selectSelectedJobMatches = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.selected
);

export const selectJobMatchesFilters = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.filters
);

export const selectJobMatchesPaginationData = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.paginationData
);

export const selectSelectedJobMatchesObjectList = createSelector(
  [selectJobMatchesList, selectSelectedJobMatches],
  (jobMatches, selectedJobMatches) =>
    jobMatches.filter(jobMatch => selectedJobMatches.includes(jobMatch.id))
);

export const selectIsSelectedAllJobMatches = createSelector(
  [selectJobMatchesList, selectSelectedJobMatches],
  (jobMatches, selectedJobMatches) =>
    (!isEmpty(jobMatches) && !isEmpty(selectedJobMatches)) &&
      (jobMatches.length === selectedJobMatches.length)
);

export const selectIsRequestingJobMatches = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.isRequesting
);

export const selectIsSubmittingJobMatch = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.isSubmitting
);

export const selectInitialSearch = createSelector(
  [selectJobMatches],
  jobMatches => jobMatches.initialSearch
);
