import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as discussionsActions from '../../../redux/actions/discussions';
import * as commentsActions from '../../../redux/actions/comments';
import * as componentsActions from '../../../redux/actions/components';
import * as authenticationActions from '../../../redux/actions/authentication';
import * as routerActions from '../../../redux/actions/router';

import { EditorState } from 'draft-js';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import WallPostErrorModal from '../../../components/dashboard/WallPostErrorModal';
import FormAndSideBar from '../../../components/skill-builders/discussions/FormAndSideBar';
import DiscussionContent from '../../../components/skill-builders/discussions/DiscussionContent';

import { withHooks } from '../../../utils/withHooks';

class Post extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    discussions: PropTypes.object.isRequired,
    discussionShare: PropTypes.object.isRequired,
    isRequestingSort: PropTypes.bool,
    modal: PropTypes.string,
    route: PropTypes.object,
    router: PropTypes.object,
    storedAction: PropTypes.string,
    isHookedToARoute: PropTypes.object,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showCustomDialog: false,
      isSortByOpen: false,
    };

    this.toggleSortBy = this.toggleSortBy.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isRequestingSort)
      this.setState({
        isSortByOpen: false
      });
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }

  handleResponse(redirectUrl) {
    if (redirectUrl) this.props.navigate(redirectUrl);

    this.setState({
      editorState: EditorState.createEmpty(),
      discussion: { post: '', attachment: {} },
      attachment: {},
      imagePreviewUrl: '',
    });
  }

  toggleSortBy(){
    this.setState({
      isSortByOpen: !this.state.isSortByOpen
    });
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  render() {
    const {
      isAuthenticated,
      profile,
      modal,
      route,
      unit
    } = this.props;

    const {
      isSortByOpen,
      showCustomDialog
    } = this.state;

    const emptyPostModal = modal && modal === 'emptyPostModal';

    return (
      <div id="discussions-post">
        {emptyPostModal &&
          <WallPostErrorModal
            isOpen={emptyPostModal}
            onClose={this.onCloseModal}/>}

        <FormAndSideBar
          isAuthenticated={isAuthenticated}
          route={route}
          unit={unit}
          profile={profile}/>

        <DiscussionContent
          {...this.props}
          isSortByOpen={isSortByOpen}
          toggleSortBy={this.toggleSortBy}/>

        {
          showCustomDialog &&
            <Modal
              size="lg"
              className="modal-margin-top"
              isOpen={showCustomDialog}
              backdrop="static"
              toggle={this.closeCustomDialog}>
              <ModalHeader
                toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
              <ModalBody>
                <p>Are you sure you want to leave this page? Any changes may be unsaved.</p>
              </ModalBody>
              <ModalFooter>
                <button
                  onClick={this.closeCustomDialog}
                  className="btn btn-secondary">
                  Stay
                </button>

                <button
                  onClick={this.onConfirmLeaveRoute}
                  className="btn btn-primary">
                  Yes, Leave
                </button>
              </ModalFooter>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { discussions, components } = state;

  const { discussionShare } = discussions;

  return {
    discussions,
    discussionShare,
    profile: state.profile.data,
    isAuthenticated: state.auth.isAuthenticated,
    modal: components.modal,
    isHookedToARoute: state.router.isHookedToARoute,
    storedAction: state.router.storedAction
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    discussionsActions,
    commentsActions,
    componentsActions,
    routerActions,
    authenticationActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Post));
