import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Prompt } from 'react-router-dom';

import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';

import * as componentsActions from '../../../redux/actions/components';
import * as discussionsActions from '../../../redux/actions/discussions';
import * as routerActions from '../../../redux/actions/router';
import * as authenticationActions from '../../../redux/actions/authentication';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import DashboardForumForm from '../../../components/dashboard/DashboardForumForm';

import { withHooks } from '../../../utils/withHooks';

class DiscussionsPostFormContainer extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    route: PropTypes.object,
    router: PropTypes.object,
    isHookedToARoute: PropTypes.object,
    storedAction: PropTypes.string,
    unit: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      nextLocation: null,
      showCustomDialog: false,
      errors: {},
      postVisibilityDropdown: false,
      attachment: {},
      plainText: '',
      imagePreviewUrl: '',
      discussion: { post: '', attachment: {}},
    };

    this.onSave = this.onSave.bind(this);
    this.handleHTMLChange = this.handleHTMLChange.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidUpdate(){

    const { attachment, imagePreviewUrl,plainText } = this.state;
   
    const hasChanged = plainText;
    const hasChangedAttachment = attachment;
    const hasChangedImagePreview = imagePreviewUrl !== '';

    if ((!isEmpty(hasChanged) || !isEmpty(hasChangedAttachment) || hasChangedImagePreview) && !this.props.isHookedToARoute.status) {
      return this.props.actions.setRouterHookedToARoute('discussions');
    }
    if( isEmpty(hasChanged) && isEmpty(hasChangedAttachment) && !hasChangedImagePreview && this.props.isHookedToARoute.status){
      this.props.actions.removeRouterHookedToARoute();
    }
  }

  componentWillUnmount() {
    this.props.actions.removeRouterHookedToARoute();
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }

  handleHTMLChange(content, delta, source, editor){
    let { discussion } = this.state;


    discussion.post = content;
    this.setState({
      discussion,
      plainText:  editor.getText(content).trim()
    });
  }

  handleResponse(redirectUrl) {
    if (redirectUrl) this.props.navigate(redirectUrl);

    this.setState({
      discussion: { post: '', attachment: {} },
      attachment: {},
      imagePreviewUrl: '',
    });
  }

  onCancel() {
    this.setState({
      attachment: {},
      imagePreviewUrl: ''
    });
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDropFile(files) {
    if(files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.setState({
      attachment: files[0],
      imagePreviewUrl: files[0].preview
    });
  }

  onSave(event) {
    event.preventDefault();

    const { discussion, attachment, plainText } = this.state;

    if(plainText === '' && isEmpty(attachment)) {
      return this.props.actions.openModal('emptyPostModal');
    }

    let formData = new FormData();

    formData.append('post', plainText === '' ? '<br>' : discussion.post);

    if (!isEmpty(attachment))
      formData.append('attachment', attachment);

    formData.append('unit', this.props.unit.id);

    this.props.actions.saveUnitDiscussion(formData)
      .then(() => this.handleResponse());
  }

  render() {
    const { discussion, showCustomDialog, imagePreviewUrl } = this.state;
    const { isSubmitting, isHookedToARoute } = this.props;

    const buttonText = isSubmitting ? 'Posting...': 'Post';

    return (
      <div className="col-lg-9">
        <div className="forum-enter">
          <div className="top">
            <div className="form-inline">
              <Prompt
                when={isHookedToARoute.status}
                message="Are you sure you want to leave this page? Any changes may be unsaved."/>
              <DashboardForumForm
                name="post"
                value={discussion.post}
                imagePreviewUrl={imagePreviewUrl}
                onDropFile={this.onDropFile}
                onCancel={this.onCancel}
                wallPost={discussion}
                handleHTMLChange={this.handleHTMLChange}/>
            </div>
          </div>
          <div className="bottom text-right">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.onSave}
              disabled={isSubmitting}>
              {buttonText}
            </button>
          </div>
        </div>

        {
          showCustomDialog &&
            <Modal
              size="lg"
              className="modal-margin-top"
              isOpen={showCustomDialog}
              backdrop="static"
              toggle={this.closeCustomDialog}>
              <ModalHeader
                toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
              <ModalBody>
                <p>Are you sure you want to leave this page? Any changes may be unsaved.</p>
              </ModalBody>
              <ModalFooter>
                <button
                  onClick={this.closeCustomDialog}
                  className="btn btn-secondary">
                  Stay
                </button>
                <button
                  onClick={this.onConfirmLeaveRoute}
                  className="btn btn-primary">
                  Yes, Leave
                </button>
              </ModalFooter>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    modal: state.components.modal,
    isSubmitting: state.discussions.isSubmitting,
    isHookedToARoute: state.router.isHookedToARoute,
    storedAction: state.router.storedAction
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    discussionsActions,
    componentsActions,
    routerActions,
    authenticationActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(DiscussionsPostFormContainer));
