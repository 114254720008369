import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as profileActions from '../../../redux/actions/profile';
import * as componentActions from '../../../redux/actions/components';

import ContactInfoSection from './ContactInfoSection';
import ContactInfoUpdateSection from './ContactInfoUpdateSection';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
} from 'reactstrap';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { pick, isEqual } from 'lodash';

import Validator from '../../../validator';

class ContactInfo extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      displayUpdateContactForm: false,
      updatedProfileData: Object.assign({}, this.props.owner),
    };

    this.handleUpdateContactInfo = this.handleUpdateContactInfo.bind(this);
    this.submitContactInfoUpdate = this.submitContactInfoUpdate.bind(this);
    this.toggleUpdateContactForm = this.toggleUpdateContactForm.bind(this);
    this.onToggleContactInfoModal = this.onToggleContactInfoModal.bind(this);
  }

  toggleUpdateContactForm() {
    this.setState({ displayUpdateContactForm: !this.state.displayUpdateContactForm });
  }

  submitContactInfoUpdate(){
    const { updatedProfileData } = this.state;
    const { actions, owner } = this.props;

    if (isEqual(updatedProfileData, owner)) {
      toastr.warning('', 'All values are unchanged.');

      return false;
    }

    if (! this.isValid()) return false;

    const updatedOwnerObject = pick(updatedProfileData, ['secondaryEmail', 'phoneNumber']);

    actions.updateSettings(updatedOwnerObject, updatedProfileData.id)
      .then(() => this.handlesResponse());
  }

  handlesResponse() {
    toastr.success('', 'Contact details updated');

    this.toggleUpdateContactForm();
  }

  isValid(field = null) {
    const { updatedProfileData, errors: prevErrors } = this.state;

    const rules = {
      email: ['required', 'email'],
      secondaryEmail: ['email'],
      phoneNumber: ['phone'],
    };

    const validate = Validator.createValidator(rules, updatedProfileData, field);
    const { isValid, errors } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  handleUpdateContactInfo(event) {
    const { target: { name, value } } = event;
    const { updatedProfileData } = this.state;

    if (name === 'email') return;

    updatedProfileData[name] = value;

    this.setState({ updatedProfileData }, () => this.isValid(name));
  }

  onToggleContactInfoModal() {
    const { modal, actions } = this.props;

    if (modal === 'contact-info') {
      actions.closeModal();
      return;
    }

    actions.openModal('contact-info');
  }

  render() {
    const { errors, updatedProfileData, displayUpdateContactForm } = this.state;
    const { owner, modal, isUpdatingProfile, currentUserId } = this.props;

    const isModalOpen = modal === 'contact-info';
    const contactDetails = 'Update Contact Info';

    return (
      <div>
        <Modal
          backdrop="static"
          isOpen={isModalOpen}
          toggle={this.onToggleContactInfoModal}>
          <ModalHeader toggle={this.onToggleContactInfoModal}>
            {displayUpdateContactForm ? contactDetails : owner.name}
          </ModalHeader>

          <ModalBody>
            {displayUpdateContactForm ?
              <ContactInfoUpdateSection
                updatedProfileData={updatedProfileData}
                errors={errors}
                handleUpdateContactInfo={this.handleUpdateContactInfo}/> :
              <ContactInfoSection
                currentUserId={currentUserId}
                updatedProfileData={updatedProfileData}
                toggleUpdateContactForm={this.toggleUpdateContactForm}/>}
          </ModalBody>

          {displayUpdateContactForm &&
            <div className="modal-footer">
              <Row>
                <Col xs="12" sm="12" xl="5" />
                <Col
                  xs="12"
                  sm="12"
                  xl="12"
                  className="offset-xl-12 offset-sm-12 text-right">
                  <Button
                    color="secondary"
                    type="button"
                    name="button"
                    onClick={this.toggleUpdateContactForm}>
                    Cancel
                  </Button>

                  <Button
                    size="sm"
                    type="button"
                    className="mx-1 sendButton btn-sm"
                    name="button"
                    disabled={isUpdatingProfile}
                    onClick={this.submitContactInfoUpdate}>
                    {isUpdatingProfile ? 'Updating ...' : 'Update Info' }
                  </Button>
                </Col>
              </Row>
            </div>}
        </Modal>
      </div>
    );
  }
}

ContactInfo.propTypes = {
  modal: PropTypes.string,
  owner: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isUpdatingProfile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    components: { modal },
    auth: { data: { currentUser: { id: currentUserId } } },
    profile: { isUpdating: isUpdatingProfile }
  } = state;

  return {
    modal,
    currentUserId,
    isUpdatingProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, profileActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
