import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal } from '../../redux/actions/components';

import { selectModal } from '../../redux/selectors/components';

import { uniqueId } from '../../utils';

import Button from '../common/Button';
import ProgressProvider from './ProgressProvider';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

const MatchScoreModal = ({
  job,
  modal,
  actions,
  progressBarFillColor
}) => {
  const percentageScore = Math.round(job.score/1*100);
  const [valueEnd] = React.useState(percentageScore);

  function closeModal() {
    actions.closeModal();
  }

  const isMatchScoreModalOpen = modal === `match-score-${job.id}`;

  const queryPartMatches = job.queryPartScores.filter(item => item.score === 1);
  const queryPartMisses = job.queryPartScores.filter(item => item.score === 0);

  return (
    <Modal
      size="lg"
      autoFocus
      centered
      scrollable
      className="modal-margin-top wall-post-error-modal"
      isOpen={isMatchScoreModalOpen}
      backdrop="static"
      toggle={closeModal}>
      <ModalHeader toggle={closeModal}/>
      <ModalBody>
        <span className="jobmatch-card-score-popover-title">Match Score</span>
        <div style={{ textAlign: 'center', width: 60, height: 60, margin: '0 auto', marginBottom: 16 }}>
          <ProgressProvider valueStart={0} valueEnd={valueEnd}>
            {value => (
              <CircularProgressbar
                value={value}
                text={`${value}`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  //                      rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',

                  // Text size
                  textSize: '24px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  // pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                  //                            pathColor: '#28a745',
                  pathColor: progressBarFillColor,
                  textColor: '#000',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                })}/>
            )}
          </ProgressProvider>
        </div>

        {(queryPartMatches.length !== 0) &&
          <span>
            What matches:
            <ul>
              {queryPartMatches
                .map(item => (<li key={uniqueId()}>{item.name}</li>))}
            </ul>
          </span>}

        {(queryPartMisses.length !== 0) &&
          <span>
            What doesn't match:
            <ul>
              {queryPartMisses
                .map(item => (<li key={uniqueId()}>{item.name}</li>))}
            </ul>
          </span>}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={closeModal}
          buttonClass="btn btn-secondary"
          buttonText="Close"/>
      </ModalFooter>
    </Modal>
  );
};

MatchScoreModal.propTypes = {
  job: PropTypes.object.isRequired,
  modal: PropTypes.string,
  actions: PropTypes.object.isRequired,
  progressBarFillColor: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  modal: selectModal(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ closeModal }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchScoreModal);
