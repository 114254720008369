import React from 'react';

import PropTypes from 'prop-types';

import { components } from 'react-select';

const JobTypeSelectOption = (props) => {
  function onChange() {
    return null;
  }

  return (
    <>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={onChange} />
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

JobTypeSelectOption.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default JobTypeSelectOption;
