import PropTypes from 'prop-types';
import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const CareerPreppedLogoOnly = require('../../assets/images/logo-icon.png');
const CareerPreppedLogo = require('../../assets/images/CP_logo.png');

const HomeHeader = ({
  toggleLoginModal,
  isMobile,
  isTalentEmployersOpen,
  toggleTalentEmployers,
  openRecruiterModal,
  toggleSignUpModal
}) => {
  const location = useLocation();
  const isSkillsToCareersInitiativePage = location.pathname === '/skills-to-careers-initiative';

  const cpLogo = isMobile ? CareerPreppedLogoOnly : CareerPreppedLogo;

  return (
    <section className="homepage-navigation main">
      <div className="navbar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <Link to="/" className="navbar-brand">
                  <img src={cpLogo}
                    className="img-responsive"
                    height="24"
                    alt="CareerPrepped"/>
                </Link>
                <ul className="navbar-nav nav-right">
                  <li className="nav-item">
                    <Link
                      className="nav-link toolkit"
                      to="/toolkit">
                      Toolkit
                    </Link>
                  </li>
                  {(!isSkillsToCareersInitiativePage) &&
                    <li className="nav-item">
                      <a className="nav-link" href="#" onClick={toggleSignUpModal}>Sign Up</a>
                    </li>}
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={toggleLoginModal}>Sign In</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row d-flex">
                <div className="col">
                  <div className="submenu">
                    <Link className="" to="/">
                      <div className="title">Talent</div>
                      <span>Students, job seekers, working adults</span>
                    </Link>
                  </div>
                </div>

                <div className="col">
                  <div className="submenu">
                    <Link className="" to="/talent-development-organizations">
                      <div className="title">Talent Developers</div>
                      <span>Educators, advisors, workforce pros</span>
                    </Link>
                  </div>
                </div>

                <div className="col">
                  <Dropdown
                    isOpen={isTalentEmployersOpen}
                    toggle={toggleTalentEmployers}
                    className="submenu">
                    <DropdownToggle caret>
                      <div className="title">Talent Employers</div>
                      <span>Recruiters, managers, industry pros</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <Link className="" to="/industry-mentor">
                          Become an industry mentor
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link to="/" onClick={openRecruiterModal}>
                          Recruit talent to hire
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeHeader.propTypes = {
  isMobile: PropTypes.bool,
  toggleLoginModal: PropTypes.func.isRequired,
  openRecruiterModal: PropTypes.func.isRequired,
  isTalentEmployersOpen: PropTypes.bool.isRequired,
  toggleTalentEmployers: PropTypes.func.isRequired,
  toggleSignUpModal: PropTypes.func.isRequired
};

export default HomeHeader;
