import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import CloseIcon from '../../../assets/images/close.svg';

function CPStatusSuccessfull({isOpen, toggleModal}) {
  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">My CareerPrepped Status</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>
      <ModalBody>
        <div className="text-center">
          <div className="congrats-status">
            <i className="fas fa-star" />
            <p>Congrats!</p>
          </div>
          <div className="mb-3">Completing these recommended steps to improve your status helps you stand out and present your best professional self.</div>
          <div>
            <button className="ml-1 sendButton btn btn-secondary" onClick={toggleModal}>Got it</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

CPStatusSuccessfull.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CPStatusSuccessfull;