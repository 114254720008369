import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import isEmpty from 'lodash/isEmpty';

import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import {
  Collapse,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  PopoverBody,
  UncontrolledPopover,
  Button
} from 'reactstrap';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

import * as skillActions from '../../redux/actions/skills';
import * as profileActions from '../../redux/actions/profile';
import * as cpStatusActions from '../../redux/actions/my-careerprepped-status';
import { getMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import AddEducation from '../../components/dashboard/my-careerprepped-status-components/AddEducation';
import AddExperience from '../../components/dashboard/my-careerprepped-status-components/AddExperience';
import AddSummary from '../../components/dashboard/my-careerprepped-status-components/AddSummary';
import AddWorkSituation from '../../components/dashboard/my-careerprepped-status-components/AddWorkSituation';
import BasicEditModal from '../profile/header/BasicEditModal';
import AddYourCustomSkills from '../../components/myskills/AddYourCustomSkills';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';

import StarStatus from '../../assets/images/status-star.svg';
import StatusBadge from '../../assets/images/status-badge.svg';
import StatusCup from '../../assets/images/status-cup.svg';
import SummaryIcon from '../../assets/images/summary.svg';
import EducationIcon from '../../assets/images/education.svg';
import ExperienceIcon from '../../assets/images/experience.svg';
import WorkSituationIcon from '../../assets/images/worksituation.svg';
import LocationIcon from '../../assets/images/location.svg';
import HeadlineIcon from '../../assets/images/headline.svg';
import FiveSkillsIcon from '../../assets/images/fiveskills.svg';
import SkillEvidenceIcon from '../../assets/images/skillevidence.svg';
import CardAvatarIcon from '../../assets/images/cardavatar.svg';
import CardBackgroundIcon from '../../assets/images/cardbackground.svg';
import SkillBuilderIcon from '../../assets/images/skillbuilder.svg';
import SkillBuilderEvidenceIcon from '../../assets/images/skillbuilderevidence.svg';
import AdvancedIcon from '../../assets/images/advanced.svg';
import BeginnerIcon from '../../assets/images/beginner.svg';
import classNames from 'classnames';
import CPStatusSuccessfull from '../../components/dashboard/my-careerprepped-status-components/CPStatusSuccessfull';

class MyCareerPreppedStatus extends Component {
  static propTypes = {
    myCareerPreppedStatus: PropTypes.object.isRequired,
    skills: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      currentPage: 1,
      maxItemsPerPage: 4,
      stageClicked: false,
      isEducationModalOpen: false,
      isExperienceModalOpen: false,
      isSummaryModalOpen: false,
      isWorkSituationModalOpen: false,
      isCPStatusSuccessfullModalOpen: false,
      isHeadlineModalOpen: false,
      fieldsToShow: false,
      modalTitle: false,
      addYourCustomSkills: false,
      isUpdatingCoverPhoto: false,
      isUpdatingAvatar: false
    };

    this.changePage = this.changePage.bind(this);
    this.toggleEducationModal = this.toggleEducationModal.bind(this);
    this.toggleExperienceModal = this.toggleExperienceModal.bind(this);
    this.toggleSummaryModal = this.toggleSummaryModal.bind(this);
    this.toggleWorkSituationModal = this.toggleWorkSituationModal.bind(this);
    this.onEditBasicInfo = this.onEditBasicInfo.bind(this);
    this.toggleAddSkills = this.toggleAddSkills.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onSelectAvatarOrCoverPhoto = this.onSelectAvatarOrCoverPhoto.bind(this);
    this.toggleCPStatusSuccessfullModal = this.toggleCPStatusSuccessfullModal.bind(this);
  }

  componentDidMount() {
    const { profile, actions } = this.props;
    //    if (!isEmpty(profile)) {
    //      actions.getMyCareerPreppedStatus(profile.id);
    //    }
    actions.skillsRequest(profile.id);
  }

  componentWillReceiveProps(nextProps) {
    const { profile, actions } = this.props;
    if (nextProps.profile.id && isEmpty(profile.id)) {
      actions.getMyCareerPreppedStatus(nextProps.profile.id);
      actions.skillsRequest(nextProps.profile.id);
    }
  }

  toggleEducationModal() {
    if (this.state.isEducationModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isEducationModalOpen: !state.isEducationModalOpen,
      };
    });
  }

  toggleExperienceModal() {
    if (this.state.isExperienceModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isExperienceModalOpen: !state.isExperienceModalOpen,
      };
    });
  }

  toggleSummaryModal() {
    if (this.state.isSummaryModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isSummaryModalOpen: !state.isSummaryModalOpen,
      };
    });
  }

  toggleWorkSituationModal() {
    if (this.state.isWorkSituationModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isWorkSituationModalOpen: !state.isWorkSituationModalOpen,
      };
    });
  }

  toggleCPStatusSuccessfullModal() {
    this.setState((state) => {
      return {
        isCPStatusSuccessfullModalOpen: !state.isCPStatusSuccessfullModalOpen,
      };
    });
  }

  onEditBasicInfo(event) {
    const passParam = event?.target?.getAttribute('data-value');
    if (this.state.isHeadlineModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isHeadlineModalOpen: !state.isHeadlineModalOpen,
        fieldsToShow: passParam,
        modalTitle: ((passParam === 'headline') ? 'Add Headline' : 'Add Location'),
      };
    });
  }

  toggleAddSkills() {
    if (this.state.addYourCustomSkills) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        addYourCustomSkills: !state.addYourCustomSkills,
      };
    });
  }

  changePage(event) {
    const direction = event.target.id;
    let { currentPage } = this.state;
    (direction === 'next') ? currentPage += 1 : currentPage -= 1;
    this.setState({ currentPage });
  }

  async onSelectAvatarOrCoverPhoto(files, fileRejections, event) {
    const { actions, profile } = this.props;

    const { name } = event.target;
    const isUpdating = name === 'avatar' ? 'isUpdatingAvatar' : 'isUpdatingCoverPhoto';

    this.setState({ [isUpdating]: true });

    const file = files[0];
    const formData = new FormData();

    formData.append(name, file);

    await actions.updateProfile(profile.id, formData);
    await actions.updateMyCareerPreppedStatus();
    actions.getMyCareerPreppedStatus();

    this.setState({ [isUpdating]: false });
  }

  generateButton(type) {
    switch (type) {
    case 'education': {
      return <button className="btn orange-btn" onClick={this.toggleEducationModal}>Add</button>;
    }

    case 'experience': {
      return <button className="btn orange-btn" onClick={this.toggleExperienceModal}>Add</button>;
    }

    case 'summary': {
      return <button className="btn orange-btn" onClick={this.toggleSummaryModal}>Add</button>;
    }

    case 'worksituation': {
      return <button className="btn orange-btn" onClick={this.toggleWorkSituationModal}>Add</button>;
    }

    case 'location': {
      return <button className="btn orange-btn" data-value="location" onClick={this.onEditBasicInfo}>Add</button>;
    }

    case 'headline': {
      return <button className="btn orange-btn" data-value="headline" onClick={this.onEditBasicInfo}>Add</button>;
    }

    case 'avatar': {
      return (
        <button className="btn orange-btn" disabled={this.state.isUpdatingAvatar}>
          <Dropzone
            multiple={false}
            className="upload-background-btn"
            accept="image/*"
            onDrop={this.onSelectAvatarOrCoverPhoto}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  {`${this.state.isUpdatingAvatar ? 'Adding...' : 'Add'}`}
                  <input {...getInputProps()} name="avatar" />
                </div>
              </section>
            )}
          </Dropzone>
        </button>
      );
    }

    case 'backgroundimage': {
      return (
        <button className="btn orange-btn" disabled={this.state.isUpdatingCoverPhoto}>
          <Dropzone
            multiple={false}
            className="upload-background-btn"
            accept="image/*"
            onDrop={this.onSelectAvatarOrCoverPhoto}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  {`${this.state.isUpdatingCoverPhoto ? 'Adding...' : 'Add'}`}
                  <input {...getInputProps()} name="background" />
                </div>
              </section>
            )}
          </Dropzone>
        </button>
      );
    }

    case 'skillBuilder': {
      return <Link className="btn orange-btn" to="/skill-builders">Join</Link>;
    }

    case 'skillBuilderEvidence': {
      return <Link className="btn orange-btn" to="/skill-builders">Add</Link>;
    }

    case 'skills': {
      return <button className="btn orange-btn" onClick={this.toggleAddSkills}>Add</button>;
    }

    case 'skillEvidence': {
      return <Link className="btn orange-btn" to="/myskills#otherSkills">Add</Link>;
    }
    }
  }

  getCardIcon(type) {
    switch (type) {
    case 'summary.svg': {
      return <img src={SummaryIcon} />;
    }

    case 'education.svg': {
      return <img src={EducationIcon} />;
    }

    case 'experience.svg': {
      return <img src={ExperienceIcon} />;
    }

    case 'worksituation.svg': {
      return <img src={WorkSituationIcon} />;
    }

    case 'location.svg': {
      return <img src={LocationIcon} />;
    }

    case 'headline.svg': {
      return <img src={HeadlineIcon} />;
    }

    case 'fiveskills.svg': {
      return <img src={FiveSkillsIcon} />;
    }

    case 'skillevidence.svg': {
      return <img src={SkillEvidenceIcon} />;
    }

    case 'cardavatar.svg': {
      return <img src={CardAvatarIcon} />;
    }

    case 'cardbackground.svg': {
      return <img src={CardBackgroundIcon} />;
    }

    case 'skillbuilder.svg': {
      return <img src={SkillBuilderIcon} />;
    }

    case 'skillbuilderevidence.svg': {
      return <img src={SkillBuilderEvidenceIcon} />;
    }
    }

  }

  toggleDropdown() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {

    const { myCareerPreppedStatus, profile, skills } = this.props;
    const currPage = parseInt(this.state.currentPage);
    const maxItems = parseInt(this.state.maxItemsPerPage);
    const start = currPage * maxItems - maxItems;
    const end = currPage * maxItems;
    const incompleteActions = myCareerPreppedStatus?.pendingCards?.slice(start, end);
    const percentile = (myCareerPreppedStatus.overallStatus) ? myCareerPreppedStatus.overallStatus : 0;
    const cpStatusTitleAlias = {
      'skills': 'Add 5 Skills',
      'summary': 'Add a Summary',
      'location': 'Add Location',
      'skillBuilder': 'Claim 3 Skill Badges',
      'skillBuilderEvidence': 'Add Portfolio Evidence  to 3 Badges',
      'skillEvidence': 'Add Evidence to 5 skills',
      'education': 'Add Education',
      'experience': 'Add Experience',
      'worksituation': 'Save Work Situation',
      'headline': 'Add Headline',
      'avatar': 'Upload Your Photo',
      'backgroundimage': 'Add Background Photo'
    };
    const userNameStyles = { color: 'black', textAlign: 'right' };
    const caretStyles = classNames('fa float-xs-right font18', {
      'fa-chevron-down': !this.state.collapse,
      'fa-chevron-up': this.state.collapse,
    });

    return (
      <div>
        <div className="home-widget-title-large d-flex justify-content-between">
          <div className="cp-status-header">
            <h3>CareerPrepped Status</h3>
            <div className="cp-status-header-right">
              <div className="cp-status-level-container">
                {(percentile > 49 && percentile < 99) ? (
                  <Button id="careerStatusButton" type="button" className="font18" style={{ textAlign: 'center' }} color="default">
                    <div className="cp-status-level-pill" id="statusStage">
                      <div> <img src={require('../../assets/images/intermediate.png')} /> </div>
                      <div>Intermediate</div>
                    </div>
                  </Button>
                ) : percentile > 99 ? (
                  <Button id="careerStatusButton" type="button" className="font18" style={{ textAlign: 'center' }} color="default">
                    <div className="cp-status-level-pill" id="statusStage">
                      <div>
                        <img src={AdvancedIcon} /> </div>
                      <div>Advanced</div>
                    </div>
                  </Button>
                ) : (
                  <Button id="careerStatusButton" type="button" className="font18" style={{ textAlign: 'center' }} color="default">
                    <div className="cp-status-level-pill" id="statusStage">
                      <div> <img src={BeginnerIcon} /> </div>
                      <div>Beginner</div>
                    </div>
                  </Button>
                )}
              </div>
              {percentile < 99 && (
                <a
                  className={classNames(
                    'nav-link profile-button hover-black clickable'
                  )}
                  onClick={this.toggleDropdown}
                  style={userNameStyles}>
                  <i className={caretStyles} />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="cp-status-progress-bar-container">
          <ProgressBar
            percent={percentile}
            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)" >
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="20"
                  src={StarStatus} />
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="20"
                  src={StatusBadge} />
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="20"
                  src={StatusCup} />
              )}
            </Step>
          </ProgressBar>
        </div>
        <UncontrolledPopover
          placement="bottom"
          target="careerStatusButton"
          className="careerstatus-popover"
          trigger="legacy">
          <PopoverBody>
            {myCareerPreppedStatus &&
              myCareerPreppedStatus?.completedCards?.map((action) => {
                return (
                  <Row key={action.id}>
                    <Col xs="10">
                      <span>{cpStatusTitleAlias[action.statusType]}</span>
                    </Col>
                    <Col xs="2" className="text-right">
                      <i className="fa fa-check-circle" />
                    </Col>
                  </Row>
                );
              }
              )}

            {myCareerPreppedStatus &&
              myCareerPreppedStatus?.pendingCards?.map((action) => {
                return (
                  <Row key={action.id}>
                    <Col xs="10">
                      <span>{cpStatusTitleAlias[action.statusType]}</span>
                    </Col>
                    <Col xs="2" className="text-right">
                      <i
                        style={{ color: 'red' }}
                        className="fa fa-times-circle" />
                    </Col>
                  </Row>
                );
              }
              )}
          </PopoverBody>
        </UncontrolledPopover>
        {percentile < 99 && (
          <div className="nav-dd" id="nav-dd">
            <Collapse isOpen={this.state.collapse}>
              <div className="sub-menu cp-status-expanded-content" id="profile">
                <Row>
                  <Col>
                    <p className="font14">
                      Your status is based on the actions below. Complete these
                      actions to improve your status.
                    </p>
                  </Col>
                </Row>
                <Row>
                  {incompleteActions &&
                    incompleteActions.map((action) => {
                      return (
                        <Col md="6" lg="3" className="mb-2" key={action.id}>
                          <Card className="cp-status-card">
                            <CardBody className="p-3 customPadding">
                              <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                                <div className="d-flex align-items-center mb-3">
                                  <div className="status-blog">
                                    {this.getCardIcon(action.icon)}
                                  </div>

                                  <SimpleToolTipWrapper
                                    id={`cp-title-${action.id}`}
                                    toolTipContent={
                                      <p>{action.title}</p>
                                    }>
                                    <CardTitle
                                      tag="h5"
                                      className="font-weight-bold font16 m-0 p-0 custom-ellipsis">
                                      {action.title}
                                    </CardTitle>
                                  </SimpleToolTipWrapper>
                                </div>
                                <div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    {this.generateButton(action.statusType)}
                                    {(action.statusType === 'skills' || action.statusType === 'skillEvidence' || action.statusType === 'skillBuilder' || action.statusType === 'skillBuilderEvidence') && (
                                      <span>{action.completedSteps} of {parseInt(action.completedSteps) + parseInt(action.neededSteps)} Added</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
                <Row className="justify-content-end">
                  <Col>
                    {this.state.currentPage > 1 ? (
                      <div onClick={this.changePage} id="back" className="d-inline-block mr-1 pointer">
                        <i id="back" className="fa fa-chevron-left mr-1 themeChevron" />
                        <span id="back"
                          className="d-inline-block themeLinkWithoutUnderline" >
                          Back
                        </span>
                      </div>
                    ) : null}
                    {myCareerPreppedStatus && myCareerPreppedStatus.pendingCards && myCareerPreppedStatus.pendingCards.length >
                      this.state.currentPage * this.state.maxItemsPerPage ? (
                        <div onClick={this.changePage} id="next" className="d-inline-block pointer">
                          <span id="next" className="d-inline-block themeLinkWithoutUnderline" >
                            Next
                          </span>
                          <i id="next" className="fa fa-chevron-right ml-1 themeChevron" />
                        </div>
                      ) : null}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
        )}

        <AddEducation
          isOpen={this.state.isEducationModalOpen}
          toggleModal={this.toggleEducationModal}
          profile={profile} />

        <AddExperience
          isOpen={this.state.isExperienceModalOpen}
          toggleModal={this.toggleExperienceModal}
          profile={profile} />

        <AddSummary
          isOpen={this.state.isSummaryModalOpen}
          toggleModal={this.toggleSummaryModal}
          profile={profile} />

        <AddWorkSituation
          isOpen={this.state.isWorkSituationModalOpen}
          toggleModal={this.toggleWorkSituationModal} />

        <CPStatusSuccessfull
          isOpen={this.state.isCPStatusSuccessfullModalOpen}
          toggleModal={this.toggleCPStatusSuccessfullModal} />

        {this.state.isHeadlineModalOpen && (
          <BasicEditModal
            isOpen={this.state.isHeadlineModalOpen}
            onCancel={this.onEditBasicInfo}
            fieldsToShow={this.state.fieldsToShow}
            modalTitle={this.state.modalTitle} />
        )}
        {
          this.state.addYourCustomSkills &&
          <AddYourCustomSkills
            isOpen={this.state.addYourCustomSkills}
            toggleModal={this.toggleAddSkills}
            addedSkillsCount={skills ? skills.length : 0} />
        }

      </div>
    );
  }
}

MyCareerPreppedStatus.propTypes = {
  myCareerPreppedStatus: PropTypes.object,
  skills: PropTypes.array,
  profile: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    skills: { data: skills },
    profile: { data: profile },
    myCareerPreppedStatus: { data: myCareerPreppedStatus }
  } = state;

  return {
    skills,
    profile,
    myCareerPreppedStatus,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getMyCareerPreppedStatus,
      ...skillActions,
      ...profileActions,
      ...cpStatusActions
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCareerPreppedStatus);
