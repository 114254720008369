import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchJobMatchSettings } from '../../redux/actions/job-match-settings';

import {
  selectJobMatchSettingsObject,
  selectIsRequestingJobMatchSettings
} from '../../redux/selectors/job-match-settings';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import JobMatches from './JobMatches';
import Loading from '../../components/common/Loading';
import JobMatchSettingsModal from './JobMatchSettingsModal';
import JobMatchEmptyState from '../../components/jobs/JobMatchEmptyState';

class JobMatch extends Component {
  static propTypes = {
    modal: PropTypes.string,
    userId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    jobMatchSettings: PropTypes.object.isRequired,
    myCareerPreppedStatus: PropTypes.object.isRequired,
    isRequestingJobMatchSettings: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
    this.props.actions.fetchJobMatchSettings(this.props.userId);
  }

  render() {
    const {
      modal,
      jobMatchSettings,
      myCareerPreppedStatus,
      isRequestingJobMatchSettings
    } = this.props;
    const isJobMatchSettingsModalOpen = modal === 'job-match-settings-modal';
    const { status: jobMatchSettingsStatus } = jobMatchSettings;

    return (
      <div
        className="col-lg-12 content jobboard"
        style={{marginTop: 30}}>
        <Helmet title="Job Match"/>
        <div>
          <h5>CareerPrepped Job Match</h5>
          <p className="mb-4">
            Let the jobs find you with Job Match! Job Match scans online job postings from across the internet to match your background and skills. Your personalized matches are based on data from your Career Site, Career Portfolio and Skill Badges and displayed below with a match score.
          </p>
        </div>
        <h5>My Job Matches</h5>

        {(isRequestingJobMatchSettings || myCareerPreppedStatus.isRequesting) && <Loading/>}

        {(myCareerPreppedStatus.data.userStatus !== 'Advanced') &&
          (!isRequestingJobMatchSettings) &&
          (!jobMatchSettingsStatus) &&
          <JobMatchEmptyState />}

        {(myCareerPreppedStatus.data.userStatus === 'Advanced') &&
          (!isRequestingJobMatchSettings) &&
          <JobMatches />}

        {isJobMatchSettingsModalOpen && <JobMatchSettingsModal/>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.components.modal,
  userId: state.auth.data.currentUser.id,
  jobMatchSettings: selectJobMatchSettingsObject(state),
  myCareerPreppedStatus: state.myCareerPreppedStatus,
  isRequestingJobMatchSettings: selectIsRequestingJobMatchSettings(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchJobMatchSettings }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobMatch);
