import React from 'react';

const SkillsStatus = () => {
  return (
    <div className="dashboard-skill-status-container">
      <div className="dashboard-skill-status-header">
        Skills Status
      </div>
      <div className="dashboard-skill-status-content">
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-progress-title">Claimed Skills (5)</div>
            <div className="dashboard-skill-status-progress-pill status--good">Good</div>
          </div>
          <div className="dashboard-skill-status-progress-bar">
            <div className="dashboard-skill-status-progress-percentage status--good" style={{width: '45%'}} />
          </div>
        </div>
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-progress-title">Avg. Skill Rating (1.2)</div>
            <div className="dashboard-skill-status-progress-pill status--low">Low</div>
          </div>
          <div className="dashboard-skill-status-progress-bar">
            <div className="dashboard-skill-status-progress-percentage status--low" style={{width: '15%'}} />
          </div>
        </div>
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-progress-title">Skill Endorsements (20)</div>
            <div className="dashboard-skill-status-progress-pill status--great">Great</div>
          </div>
          <div className="dashboard-skill-status-progress-bar">
            <div className="dashboard-skill-status-progress-percentage status--great" style={{width: '75%'}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsStatus;
