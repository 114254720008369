import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';
import Validator from '../../validator';
import Loading from '../../components/common/Loading';
import Details from '../../components/jobs/Details';

import * as jobNotesActions from '../../redux/actions/job-notes';
import * as jobBoardActions from '../../redux/actions/job-boards';
import * as componentsActions from '../../redux/actions/components';

class SavedJobUnit extends Component {
  static propTypes = {
    jobNotes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    noteForm: PropTypes.object.isRequired,
    loadForms: PropTypes.array.isRequired,
    jobDetails: PropTypes.object,
    modal: PropTypes.string,
    navigate: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      showMore: false,
      notesShowMore: [],
      deleteNoteId: null,
      jobStatusDropdownOpen: false,
      noteForm: Object.assign({}, this.props.noteForm)
    };

    this.onNotePost = this.onNotePost.bind(this);
    this.onChangeNoteForm = this.onChangeNoteForm.bind(this);
    this.onSelectJobStatus = this.onSelectJobStatus.bind(this);
    this.togglejobStatusDropdown = this.togglejobStatusDropdown.bind(this);
    this.onEditDetails = this.onEditDetails.bind(this);
    this.onDeleteNote = this.onDeleteNote.bind(this);
    this.onCancelDeleteNote = this.onCancelDeleteNote.bind(this);
    this.onConfirmDeleteNote = this.onConfirmDeleteNote.bind(this);
    this.onShowMore = this.onShowMore.bind(this);
    this.onNotesShowMore = this.onNotesShowMore.bind(this);
  }

  componentWillMount(){
    const { navigate, jobDetails } = this.props;

    if(!jobDetails) {
      navigate(-1);
    }
  }

  componentDidMount(){
    const { slug, actions, currentUser } = this.props;

    actions.fetchJobNotes(slug, currentUser.id);
  }

  componentWillReceiveProps(nextProps) {
    const { id: nextJobNoteId } = nextProps.jobNotes.isDeleting;
    const { id: jobNoteId } = this.props.jobNotes.isDeleting;

    if ((nextJobNoteId !== jobNoteId) && (nextJobNoteId === null)) {
      this.onCancelDeleteNote();
    }
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      text: ['required'],
    }, this.state.noteForm, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  togglejobStatusDropdown() {
    const { jobStatusDropdownOpen } = this.state;
    const { currentUser, jobDetails } = this.props;

    if (currentUser.id !== jobDetails.user.id) {
      return;
    }

    this.setState({
      jobStatusDropdownOpen: !jobStatusDropdownOpen
    });
  }

  onSelectJobStatus(jobId, statusId){
    const { updateJobDetails } = this.props.actions;

    return ()=>{
      this.setState({jobStatusDropdownOpen: false});

      updateJobDetails({
        id: jobId,
        status: statusId
      });
    };
  }

  onEditDetails(formId){
    const { actions } = this.props;

    return function() {
      actions.loadForm(formId);
    };
  }

  onNotePost(event){
    const { slug, actions } = this.props;
    const { text } = this.state.noteForm;
    const noteForm = { text: ''};

    if (!this.isValid()) return ;

    event.preventDefault();
    actions.addNote(slug, text)
      .then(()=>{
        this.setState({ noteForm });
        toastr.success('Note added');
      });
  }

  onDeleteNote(deleteNoteId) {
    return () => {
      this.setState({ deleteNoteId });
      this.props.actions.openModal('confirmDeleteNoteModal');
    };
  }

  onCancelDeleteNote() {
    this.setState({ deleteNoteId: null });
    this.props.actions.closeModal();
  }

  onConfirmDeleteNote() {
    const { deleteNoteId } = this.state;
    const { deleteNote } = this.props.actions;

    deleteNote(deleteNoteId)
      .then(() => this.setState({ deleteNoteId: null }));
  }

  onChangeNoteForm(event){
    event.preventDefault();

    const field = event.currentTarget['name'];
    let noteForm = this.state.noteForm;
    noteForm[field] = event.currentTarget['value'];

    this.setState({ noteForm });

    this.isValid();
  }

  onShowMore(event){
    event.preventDefault();

    const { showMore } = this.state;

    this.setState({
      showMore:!showMore
    });
  }

  onNotesShowMore(id){
    return () => {
      const { notesShowMore } = this.state;

      const noteIndex = notesShowMore.findIndex(note => note == id);

      const newNotesShowMore = noteIndex === -1 ? [ ...notesShowMore, id]:
        [...notesShowMore.slice(0, noteIndex), ...notesShowMore.slice(noteIndex+1)];

      this.setState({
        notesShowMore: newNotesShowMore
      });
    };
  }

  render() {
    const { noteForm, jobStatusDropdownOpen, errors, showMore, notesShowMore } = this.state;
    const {
      modal,
      loadForms,
      jobNotes: notes,
      jobDetails,
      currentUser
    } = this.props;

    const {
      isRequesting,
      isSubmitting,
      isDeleting,
      data: jobNotes
    } = notes;

    const confirmDeleteNoteModalIsOpen = modal === 'confirmDeleteNoteModal';

    return (
      <div className="col-lg-12 content jobboard" style={{marginTop: 30}}>
        <Helmet title={`My Jobs - ${jobDetails.title}`}/>
        {isRequesting ?
          <Loading />:
          <Details
            errors={errors}
            loadForms={loadForms}
            noteForm={noteForm}
            currentUser={currentUser}
            onChangeNoteForm={this.onChangeNoteForm}
            onNotePost={this.onNotePost}
            isSubmitting={isSubmitting}
            jobNotes={jobNotes}
            jobStatusDropdownOpen={jobStatusDropdownOpen}
            togglejobStatusDropdown={this.togglejobStatusDropdown}
            onSelectJobStatus={this.onSelectJobStatus}
            onEditDetails={this.onEditDetails}
            onDeleteNote={this.onDeleteNote}
            onEditNote={this.onEditDetails}
            onShowMore={this.onShowMore}
            showMore={showMore}
            onNotesShowMore={this.onNotesShowMore}
            notesShowMore={notesShowMore}
            data={jobDetails}
            isDeleting={isDeleting}
            confirmDeleteNoteModalIsOpen={confirmDeleteNoteModalIsOpen}
            onConfirmDeleteNote={this.onConfirmDeleteNote}
            onCancelDeleteNote={this.onCancelDeleteNote}/>}
      </div>
    );
  }
}

const getJobDetails = (boards, slug) => {
  return boards.find(job => job.id === slug);
};

const mapStateToProps = (state, ownProps) => {
  const slug = ownProps.params.slug;
  const { jobBoards, components, jobNotes } = state;
  let noteForm = {
    text: '',
  };
  const jobDetails = getJobDetails(jobBoards.data, slug);

  return {
    noteForm,
    slug,
    jobNotes,
    jobDetails,
    modal: components.modal,
    loadForms: components.forms,
    currentUser: state.auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobNotesActions, jobBoardActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(SavedJobUnit));
