import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import Validator from '../../validator';
import { toastr } from 'react-redux-toastr';
import JobSearchbar from '../../components/jobs/JobSearchbar';
import JobResult from '../../components/jobs/JobResult';

import Loading from '../../components/common/Loading';

import { omit, isEmpty, isNull } from 'lodash';

import {
  selectFormattedStates,
  selectStatesPageCount
} from '../../redux/selectors/states';
import {
  selectFormattedCities,
  selectCitiesPageCount
} from '../../redux/selectors/cities';

import { getStates } from '../../redux/actions/states';
import { getCities } from '../../redux/actions/cities';
import * as jobBoardActions from '../../redux/actions/job-boards';
import * as jobSearchActions from '../../redux/actions/job-search';
import * as componentsActions from '../../redux/actions/components';

class IndeedJobs extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    jobSearch: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    states: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    statesPageCount: PropTypes.number.isRequired,
    citiesPageCount: PropTypes.number.isRequired,
    jobBoards: PropTypes.object.isRequired,
    modal: PropTypes.string
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: '',
      errors: {},
      stateId: '',
      jobkey: null,
      isSubmittingRequest: false,
      search: Object.assign({}, this.props.search, { remoteJobs: false })
    };

    this.updateSearchState = this.updateSearchState.bind(this);
    this.searchJobs = this.searchJobs.bind(this);
    this.addToBoard = this.addToBoard.bind(this);
    this.applyToJob = this.applyToJob.bind(this);

    this.deleteJob = this.deleteJob.bind(this);
    this.onConfirmDeleteJob = this.onConfirmDeleteJob.bind(this);
    this.onCancelDeleteJob = this.onCancelDeleteJob.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
    this.toggleRemoteJobs = this.toggleRemoteJobs.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onChangeDropdown = this.onChangeDropdown.bind(this);
    this.onClearKeywords = this.onClearKeywords.bind(this);
    this.onToggleAdvancedFilter = this.onToggleAdvancedFilter.bind(this);
  }

  async loadOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;
    const { actions, profile } = this.props;

    if (inputName === 'state') {
      // default to US
      const country = profile.country ? profile.country.value : '1eb2d271-1f99-11ec-a80e-0242ac120003';

      await actions.getStates(country, page, search);
    }

    if (inputName === 'city') {
      await actions.getCities(this.state.search.state.value, page, search);
    }

    const options = (inputName === 'state') ? this.props.states : this.props.cities;

    const pageCount = (inputName === 'state') ?
      this.props.statesPageCount : this.props.citiesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeDropdown(value, actionMeta) {
    const { name } = actionMeta;

    this.setState({
      search: {
        ...this.state.search,
        [name]: value,
        ...((name === 'state') && { city: null })
      },
      ...((name === 'state') && { stateId: value.id })
    },
    () => this.isValid(name));
  }

  toggleRemoteJobs() {
    this.setState({
      search: { ...this.state.search, remoteJobs: !this.state.search.remoteJobs }
    });
  }

  updateSearchState(event){
    event.preventDefault();

    const field = event.currentTarget['name'];
    let search = this.state.search;

    search[field] = event.currentTarget['value'];

    this.setState({ search });

    this.isValid(field);
  }

  onToggleAdvancedFilter() {
    this.props.actions.toggleAdvancedFilter();
  }

  isValid(field = null) {
    const rules = {
      query: ['required']
    };
    const { search } = this.state;

    const validate = Validator.createValidator(rules, search, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  addToBoard(jobkey){
    return () => {
      const { addJobToBoard, updateJobSearchProperties } = this.props.actions;

      addJobToBoard(jobkey)
        .then(() => {
          toastr
            .success('Success', 'This job was successfully saved! See all your saved jobs in "Job Tracker".');
          updateJobSearchProperties({ jobkey, isSavedProperty: 1 });
        }, () => {
          toastr.error('Already Saved', 'This job is saved in your "Job Tracker" section of the Job Center. Click on "Job Tracker" to change the status of this job to applied.');
        });
    };
  }

  applyToJob(jobkey, url){
    return (event) => {
      event.preventDefault();

      const { addJobToBoard, updateJobSearchProperties } = this.props.actions;
      let wi = window.open('about:blank', '_blank');

      wi.location.href = url;

      addJobToBoard(jobkey, 'apply')
        .then(() => {
          toastr
            .success('Success', 'This job was successfully saved and the status has been set to "Applied". See all your saved jobs in "Job Tracker".');
          updateJobSearchProperties({ jobkey, isSavedProperty: 1 });
        }, () => {
          toastr.error('Already Saved', 'This job is saved in your "Job Tracker" section of the Job Center. Click on "Job Tracker" to change the status of this job to applied.');
        });
    };
  }

  deleteJob(jobkey){
    return () => {
      this.setState({ jobkey });
      this.props.actions.openModal('confirmDeleteJobModal');
    };
  }

  onConfirmDeleteJob() {
    const { jobkey } = this.state;
    const job = this
      .props
      .jobBoards
      .data
      .find(job => job.jobkey === jobkey);

    const {
      deleteJobFromBoard,
      updateJobSearchProperties
    } = this.props.actions;

    deleteJobFromBoard(job.id)
      .then(() => {
        this.setState({ jobkey: null });
        updateJobSearchProperties({ jobkey: job.jobkey, isSavedProperty: 0 });
        toastr.success('', 'Job successfully unsaved and removed from Job Tracker');
      });
  }

  onCancelDeleteJob() {
    this.setState({ jobkey: null });
    this.props.actions.closeModal();
  }

  onChangeJobType = (jobType) => {
    let search = Object.assign({ ...this.state.search }, { jobType });

    this.setState({ search });
  }

  onChangeRadius = (radius) => {
    const search = Object.assign({ ...this.state.search }, { radius });

    this.setState({ search });

    this.updateQueryParams(search);
  }

  onChangeSort = (sort) => {
    const search = Object.assign({ ...this.state.search }, { sort });

    this.setState({ search }, () => {
      this.searchJobs();
    });

    this.updateQueryParams(search);
  }

  onChangeIndustry = (industry) => {
    const search = Object.assign({ ...this.state.search }, { industry });

    this.setState({ search });
  }

  onClearKeywords() {
    const search = Object.assign({ ...this.state.search }, { query: '' });

    this.setState({ search });
  }

  onClickReset(event) {
    event.preventDefault();

    const search = Object.assign(
      { ...this.state.search },
      {
        query: '',
        city: '',
        state: '',
        industry: '',
        remoteJobs: false,
        sort: {
          value: 'relevance',
          label: 'Relevance'
        }
      }
    );

    this.setState({ search });
  }

  updateQueryParams(search) {
    search = Object.assign({}, search, { start: search.startIndex });
    search = omit(search, ['startIndex']);

    this.props.actions.updateQueryParams(search);
  }

  searchJobs() {
    const { search } = this.state;
    const { searchJobs } = this.props.actions;

    if ((search.query.length < 2) && isEmpty(search.industry) && ((search.city === '' || search.state === '') && (!search.remoteJobs))) {
      this.setState({
        error: 'Try adding keywords or choose an industry. Additionally, add state and city or check "Search remote jobs only."'
      });
      return;
    }

    if ((search.city === '' || search.state === '') && (!search.remoteJobs)) {
      this.setState({
        error: 'Try entering a state and city, or check "Search remote jobs only."'
      });
      return;
    }

    if (((search.city === '' && search.state !== '') || (isNull(search.city) && search.state !== '')) && (!search.remoteJobs)) {
      this.setState({
        error: 'Try entering a state and city, or check "Search remote jobs only."'
      });
      return;
    }

    if (((search.city !== '' && search.state !== '') || (search.remoteJobs)) && ((isEmpty(search.industry) && (search.query.length < 2)))) {
      this.setState({
        error: 'Try adding keywords or choose an industry.'
      });
      return;
    }

    this.setState({ error: '' });

    const sort = search.sort;
    const locationString = isNull(search.city) ? search.state.abbr : `${search.city.label}, ${search.state.abbr}`;
    const location = search.remoteJobs ? 'Remote' : locationString;

    const queryParams = { ...search, sort, location };

    this.updateQueryParams(queryParams);

    this.setState({ isSubmittingRequest: true });

    searchJobs(queryParams).then(() => this.setState({ isSubmittingRequest: false }));
  }

  render() {
    const { search, error, errors, stateId, isSubmittingRequest } = this.state;
    const { modal, jobSearch } = this.props;
    const { isRequesting, data: searchResult, displayAdvancedFilter } = jobSearch;
    const initiatedCondition = Object.keys(searchResult).length !== 0;
    const confirmDeleteJobModalIsOpen = modal === 'confirmDeleteJobModal';

    return (

      <div className="col-lg-12 content jobboard" style={{marginTop: 30}}>
        <div>
          <h5>CareerPrepped Job Match</h5>
          <p className="mb-4">
            Stop switching between tabs for job searches! With Job Match powered by AI, you can search thousands of job postings all in one spot. Get personalized matches tailored to your preferences instantly!
          </p>
        </div>
        <JobSearchbar
          search={search}
          errors={errors}
          isFinding={isRequesting}
          onToggleAdvancedFilter={this.onToggleAdvancedFilter}
          displayAdvancedFilter={displayAdvancedFilter}
          onChange={this.updateSearchState}
          onChangeJobType={this.onChangeJobType}
          onChangeRadius={this.onChangeRadius}
          onChangeSort={this.onChangeSort}
          onSubmit={this.searchJobs}
          stateId={stateId}
          loadOptions={this.loadOptions}
          onChangeDropdown={this.onChangeDropdown}
          toggleRemoteJobs={this.toggleRemoteJobs}
          onChangeIndustry={this.onChangeIndustry}
          onClearKeywords={this.onClearKeywords}
          onClickReset={this.onClickReset}/>

        {isSubmittingRequest && <Loading/>}

        {error.length !== 0 &&
          <div>
            <p
              style={{marginTop: '2rem'}}
              className="text-center">
              No results found.<br/>
              {error}
            </p>
          </div>}

        {initiatedCondition && (!isSubmittingRequest) &&
          <JobResult
            search={search}
            addToBoard={this.addToBoard}
            applyToJob={this.applyToJob}
            searchResult={searchResult}
            deleteJob={this.deleteJob}
            onChangeSort={this.onChangeSort}
            confirmDeleteJobModalIsOpen={confirmDeleteJobModalIsOpen}
            onConfirmDeleteJob={this.onConfirmDeleteJob}
            onCancelDeleteJob={this.onCancelDeleteJob}/>}
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { profile, jobBoards, jobSearch, components } = state;
  const search = Object.assign(
    {},
    jobSearch.queryParams,
    { startIndex: jobSearch.queryParams.start }
  );

  return {
    search,
    jobSearch,
    jobBoards,
    modal: components.modal,
    profile: profile.data,
    states: selectFormattedStates(state),
    statesPageCount: selectStatesPageCount(state),
    cities: selectFormattedCities(state),
    citiesPageCount: selectCitiesPageCount(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobSearchActions, jobBoardActions, componentsActions);

  return {
    actions: bindActionCreators({ ...actions, getStates, getCities }, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(IndeedJobs);
