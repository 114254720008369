import React, { Component } from 'react';
import Slider from 'react-slick';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 12000,
      dots: true,
      fade: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      pauseOnHover: true,
      adaptiveHeight: true,
      slidesToScroll: 1
    };
    const img_testimonial_1 = require('../../assets/images/homepage/organization/img-testimonial-mconnet.png');
    const img_testimonial_2 = require('../../assets/images/homepage/organization/img-testimonial-mcommis.png');
    const img_testimonial_3 = require('../../assets/images/homepage/organization/img-testimonial-kelly.png');
    const img_testimonial_4 = require('../../assets/images/homepage/organization/img-testimonial-levy.png');
    const img_testimonial_5 = require('../../assets/images/homepage/organization/img-testimonial-berezansky.png');
    return (
      <div>
        <Slider {...settings}>
          <div className="item">
            <img src={img_testimonial_1} alt="Michael Connet" />
            <p>“CareerPrepped is destined to become a critical part of preparing the nation's learners for the world of work, with a rich set of workforce readiness tools and an environment that allows learners and potential employers to connect.”</p>
            <div>
              <div className="name">Michael Connet</div>
              <div className="position">Associate Deputy Executive Director</div>
              <div className="inst">Association for Career and Technical Education (ACTE)</div>
            </div>
          </div>
          <div className="item">
            <img src={img_testimonial_2} alt="Dr. Michale McComis" />
            <p>“Essential workforce skills, often referred to as soft skills, are a necessary part of any high-quality career education program. We're particularly pleased with CareerPrepped's self-directed and competency-based approach to help learners build their soft skills.”</p>
            <div>
              <div className="name">Dr. Michale McComis</div>
              <div className="position">Executive Director</div>
              <div className="inst">Accrediting Commission of Career Schools and Colleges (ACCSC)</div>
            </div>
          </div>
          <div className="item">
            <img src={img_testimonial_3} alt="Mary Kelley" />
            <p>"At StrataTech, our dedication to student and graduate success is unwavering. That's why our teams across all campuses unanimously selected CareerPrepped to take our support to the next level. CareerPrepped helps us streamline our career services and integrate vital skill development into our curriculum, allowing staff to focus more on valuable coaching and feedback for students. </p>
            <p>The platform also allows us to offer 24/7, on-demand support to all students, graduates, and alumni whenever they need it. Moreover, CareerPrepped helps further differentiate our graduates in the competitive labor market and reinforces our schools' distinction as the preferred source of top-tier talent for local employers in the industries we serve."</p>  
            <div>
              <div className="name">Mary Kelley</div>
              <div className="position">President/CEO</div>
              <div className="inst">StrataTech Education Group</div>
            </div>
          </div>
          <div className="item">
            <img src={img_testimonial_4} alt="Maryse Levy" />
            <p>“Following our adoption of Blockchain technologies to issue certificates and degrees, CareerPrepped provides us with yet another opportunity to empower our students and alumni to be in control of their achievements while supplying high quality talent to employers with verifiable qualifications and skills.”</p>
            <div>
              <div className="name">Maryse Levy</div>
              <div className="position">Former Vice President of Student Development</div>
              <div className="inst">ECPI University</div>
            </div>
          </div>
          <div className="item">
            <img src={img_testimonial_5} alt="Joy Berezansky" />
            <p>“CareerPrepped fit nicely into what we wanted to address. It enabled us to transfer 'soft skill' development and demonstration into the entire school - instituting it schoolwide in all programs and even in counseling departments, student support, etc.”</p>
            <div>
              <div className="name">Joy Berezansky</div>
              <div className="position">Instructor</div>
              <div className="inst">Commonwealth Technical Institute</div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}