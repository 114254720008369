import { CALL_API } from '../../middlewares/api';

import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE
} from '../../constants';

export const getCountries = (page = 1, query = '') => {
  let endpoint = `countries?page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        COUNTRIES_REQUEST,
        COUNTRIES_SUCCESS,
        COUNTRIES_FAILURE
      ]
    }
  };
};
