import React from 'react';

import PropTypes from 'prop-types';

import { components } from 'react-select';

const LocationFilterSelectOption = (props) => {
  function onChange() {
    return null;
  }

  return (
    <>
      <components.Option {...props}>
        {(props.value === 'remoteJob') &&
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={onChange} />}
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

LocationFilterSelectOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default LocationFilterSelectOption;
